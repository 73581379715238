import React, { useCallback, useState } from 'react'

import {
  Button,
  PlusIcon,
  EmptyState,
  CriticalIcon,
  Text,
  Notice,
} from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { useCalendarAnalytics } from '~publish/pages/Calendar/hooks/useCalendarAnalytics'
import SegmentedControl from '@bufferapp/ui/SegmentedControl'
import { useTwentyFourHourTimeFormat } from '~publish/pages/Calendar/hooks/useTwentyFourHourTimeFormat'
import { FilterByChannel } from '~publish/components/FilterByChannel'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useCalendarState } from '~publish/pages/Calendar/hooks/useCalendarState'
import isViewOnly from '~publish/pages/Calendar/hooks/useIsViewOnly'
import { ThreadsFreeOBPaywall } from '~publish/legacy/composer/composer/components/ThreadsFreeOBPaywall'
import { usePostComposer } from '~publish/hooks/usePostComposer'

import CalendarNavigation from '../CalendarNavigation'
import { CalendarGrid } from '../CalendarGrid/CalendarGrid'
import Timezone from '../Timezone'
import { TypeFilter } from '../TypeFilter'

import {
  CalendarHeader as LegacyCalendarHeader,
  CalendarHeaderRightSide,
  CalendarPage,
} from './style'

import styles from './Calendar.module.css'

const options = [
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
]

const Calendar = (): JSX.Element => {
  const calendarState = useCalendarState()
  const { filters, navigation, postsQuery } = calendarState
  const { granularity, navigateGranularity } = navigation
  const { channelsFilter, channels, error } = filters
  const isWeekly = granularity === 'week'
  const hasTwentyFourHourTimeFormat = useTwentyFourHourTimeFormat()

  const organizationId = useOrganizationId() ?? ''
  const {
    onWeekClick,
    onMonthClick,
    onCalendarChangeTrack,
    onDropPostSuccess,
    onDropPostError,
  } = useCalendarAnalytics({
    granularity: navigation.granularity,
    channelsCount: channelsFilter.sanitized.length,
    organizationId,
  })
  const { triggerAttributes, createNewPostInComposer } = usePostComposer()

  const { isEnabled: isSecondaryButtonTreatmentEnabled } = useSplitEnabled(
    'geid-secondary-button-treatment-with-global-action',
  )

  const viewOnly = isViewOnly({
    channelsSelected: channelsFilter.sanitized,
    listOfChannels: channels,
  })

  const handleWeekMonthToggle = useCallback(
    (value: string): void => {
      if (value !== 'week' && value !== 'month') return

      if (value === 'week') onWeekClick(isWeekly)
      else if (value === 'month') onMonthClick(isWeekly)
      navigateGranularity(value)
    },
    [isWeekly, onWeekClick, onMonthClick, navigateGranularity],
  )

  const handleForwardsBackwardsClick = useCallback(
    (direction: 'forwards' | 'backwards' | 'today'): void => {
      const modifier =
        {
          forwards: 'next',
          backwards: 'previous',
          today: 'today',
        }[direction] === 'forwards'
          ? 'next'
          : 'previous'
      onCalendarChangeTrack(granularity, `${modifier}_${granularity}`)
      if (direction === 'today') {
        navigation.setCurrentDate(new Date())
      } else {
        navigation.onNavigate(direction, granularity)
      }
    },
    [navigation, onCalendarChangeTrack, granularity],
  )

  const handleToday = useCallback((): void => {
    const source = granularity
    onCalendarChangeTrack(source, 'today')
    navigation.setCurrentDate(new Date())
  }, [granularity, navigation, onCalendarChangeTrack])

  const [openModal, setOpenModal] = useState({
    open: false,
    ctaString: '',
    service: '',
  })

  if (error) {
    return (
      <EmptyState size="medium">
        <EmptyState.Icon variant="critical">
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Failed to load</EmptyState.Heading>
        <EmptyState.Description>
          Error happened, please let our team know about it.{' '}
          <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  const threadsFreeOBPaywallSnippet = openModal?.open && (
    <ThreadsFreeOBPaywall
      closeFirstModal={(): void =>
        setOpenModal({ open: false, ctaString: '', service: '' })
      }
      ctaString={openModal.ctaString}
      service={openModal.service}
    />
  )

  return (
    <CalendarPage>
      {threadsFreeOBPaywallSnippet}
      <LegacyCalendarHeader>
        <CalendarNavigation
          currentDate={navigation.currentDate}
          isWeekly={isWeekly}
          onPreviousClick={(): void =>
            handleForwardsBackwardsClick('backwards')
          }
          onNextClick={(): void => handleForwardsBackwardsClick('forwards')}
          onTodayClick={handleToday}
          loading={false} // dynamically change when we have data
        />
        <CalendarHeaderRightSide>
          <Timezone />
          <FilterByChannel
            channels={channels}
            value={channelsFilter.sanitized}
            onSelect={channelsFilter.onSelect}
          />
          <TypeFilter />
          <SegmentedControl>
            {options.map(({ label, value }) => (
              <SegmentedControl.Option
                key={value}
                optionType="text"
                label={label}
                value={value}
                selected={value === granularity}
                onClick={handleWeekMonthToggle}
              />
            ))}
          </SegmentedControl>
          <Button
            {...triggerAttributes}
            size="large"
            variant={
              isSecondaryButtonTreatmentEnabled ? 'secondary' : 'primary'
            }
            onClick={(): Promise<void> =>
              createNewPostInComposer({
                cta: 'publish-calendar-header-newPost-1',
                channels: channelsFilter.queryParams ?? [],
              })
            }
          >
            <PlusIcon /> New Post
          </Button>
        </CalendarHeaderRightSide>
      </LegacyCalendarHeader>
      {viewOnly && (
        <Notice variant="warning" className={styles.CalendarNotice}>
          Looks like you don’t have full posting permissions for all channels.
          Visit individual Queues to create or manage posts.
        </Notice>
      )}
      <CalendarGrid
        hasTwentyFourHourTimeFormat={hasTwentyFourHourTimeFormat}
        onDropPostSuccess={onDropPostSuccess}
        onDropPostError={onDropPostError}
        setOpenModal={setOpenModal}
        isViewOnly={viewOnly}
        navigation={navigation}
        granularity={granularity}
        channelsFilter={channelsFilter}
        postsQuery={postsQuery}
      />
    </CalendarPage>
  )
}

export default Calendar
