import { useEffect, useMemo } from 'react'
import { type ApolloError, useLazyQuery } from '@apollo/client'
import { useCallbackRef } from '@buffer-mono/popcorn'
import { makeUseGlobalStateHook } from './useGlobalState'

import { graphql } from '~publish/gql'
import { PusherEvent, usePusherEvent } from '~publish/services/pusher'

const allPostEvents = [
  PusherEvent.POST_CREATED,
  PusherEvent.POST_UPDATED,
  PusherEvent.POST_DELETED,
  PusherEvent.POST_SENT,
  PusherEvent.POST_TAG_ADDED,
  PusherEvent.POST_TAG_REMOVED,
  PusherEvent.DRAFT_MOVED,
  PusherEvent.DRAFT_UPDATED,
  PusherEvent.DRAFT_APPROVED,
  PusherEvent.QUEUE_CHANGED,
]

export const GetPostCountsByChannel = graphql(/* GraphQL */ `
  query GetPostCountsByChannel(
    $organizationId: OrganizationId!
    $channelIds: [ChannelId!]
    $tagIds: [TagId!]
  ) {
    drafts: postCounts(
      input: {
        organizationId: $organizationId
        filter: { channelIds: $channelIds, tagIds: $tagIds, status: [draft] }
      }
    ) {
      counts {
        channelId
        count
        limit
      }
    }

    queue: postCounts(
      input: {
        organizationId: $organizationId
        filter: {
          channelIds: $channelIds
          tagIds: $tagIds
          status: [scheduled, error]
        }
      }
    ) {
      counts {
        channelId
        count
        limit
      }
    }

    approvals: postCounts(
      input: {
        organizationId: $organizationId
        filter: {
          channelIds: $channelIds
          tagIds: $tagIds
          status: [needs_approval]
        }
      }
    ) {
      counts {
        channelId
        count
        limit
      }
    }

    sent: postCounts(
      input: {
        organizationId: $organizationId
        filter: {
          channelIds: $channelIds
          tagIds: $tagIds
          status: [sent, sending]
        }
      }
    ) {
      counts {
        channelId
        count
        limit
      }
    }
  }
`)

type PostCountsValues = {
  drafts: number
  approvals: number
  sent: number
  queue: number
  limit: number | null
}

type CountsByChannel = Record<string, PostCountsValues>
type ApolloCountsResult<T> = {
  counts: T
  loading: boolean
  error?: ApolloError | null
}

const defaultCacheValue: Omit<
  ApolloCountsResult<CountsByChannel>,
  'loading'
> = {
  error: null,
  counts: {},
}
const usePostsCountByChannelCache = makeUseGlobalStateHook<
  Record<string, ApolloCountsResult<CountsByChannel>>
>({})
const loadingQueries = new Set<string>()

type PostCountsProps = {
  organizationId: string
  channelIds?: string[]
  tagIds?: string[]
}

const getEmptyCounts = (): PostCountsValues => ({
  drafts: 0,
  approvals: 0,
  sent: 0,
  queue: 0,
  limit: null,
})

const usePostCountsByChannel = (
  props: PostCountsProps,
): ApolloCountsResult<CountsByChannel> => {
  const { organizationId, channelIds, tagIds } = props
  const variables = useMemo(
    () => ({
      organizationId,
      channelIds: channelIds && channelIds.length > 0 ? channelIds : undefined,
      tagIds: tagIds && tagIds.length > 0 ? tagIds : undefined,
    }),
    [organizationId, channelIds, tagIds],
  )
  const [getPostCounts] = useLazyQuery(GetPostCountsByChannel, {
    variables,
    // caching breaks for this query as all 'totalCount' fragments
    // are cached in an overlapping manner
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const cacheKey = useMemo(() => JSON.stringify(variables), [variables])

  const [cache, setCounts] = usePostsCountByChannelCache()

  const fetchAndUpdateCache = useCallbackRef(async () => {
    if (loadingQueries.has(cacheKey)) return
    loadingQueries.add(cacheKey)

    const { data, error } = await getPostCounts({
      variables,
    })

    const newState: CountsByChannel = {}
    for (const key of ['drafts', 'approvals', 'sent', 'queue'] as const) {
      for (const value of data?.[key]?.counts ?? []) {
        newState[value.channelId] =
          newState[value.channelId] ?? getEmptyCounts()
        newState[value.channelId][key] += value.count
        newState[value.channelId].limit = value.limit ?? null
      }
    }

    // update with loaded data
    setCounts((previous) => ({
      ...previous,
      [cacheKey]: {
        counts: newState,
        loading: false,
        error,
      },
    }))
    loadingQueries.delete(cacheKey)
    return newState
  })

  useEffect(() => {
    if (cacheKey in cache) return
    fetchAndUpdateCache()
  }, [cache, cacheKey, fetchAndUpdateCache])

  usePusherEvent(allPostEvents, (data) => {
    // Only refetch if the channel is in the list of channels
    // or if the list of channels is undefined
    if (
      channelIds?.includes(data.profile_id) ||
      channelIds === undefined ||
      channelIds.length === 0
    ) {
      fetchAndUpdateCache()
    }
  })

  return (
    cache[cacheKey] ?? {
      ...defaultCacheValue,
      loading: true,
    }
  )
}

type UsePostsCountResult = ApolloCountsResult<PostCountsValues | null>

export const usePostCounts = (props: PostCountsProps): UsePostsCountResult => {
  const result = usePostCountsByChannel({
    organizationId: props.organizationId,
    tagIds: props.tagIds,
  })
  const counts = useMemo(() => {
    const totals = getEmptyCounts()
    const byChannel = Object.entries(result.counts)
    if (byChannel.length === 0) return null
    for (const [channelId, value] of byChannel) {
      const included =
        props.channelIds?.includes(channelId) ||
        props.channelIds?.length === 0 ||
        props.channelIds === undefined
      if (!included) continue
      for (const key of ['drafts', 'approvals', 'sent', 'queue'] as const) {
        totals[key] += value[key]
      }
      totals.limit = value.limit ?? null
    }
    return totals
  }, [result.counts, props.channelIds])

  return {
    ...result,
    counts,
  }
}
