import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case actionTypes.SET_USER_LANGUAGE:
        dispatch(
          dataFetchActions.fetch({
            name: 'setUserLanguage',
            args: {
              language: action.language,
            },
          }),
        )
        break
      case `setUserLanguage_${dataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: action.result.message,
          }),
        )
        break
      case `setUserLanguage_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: 'There was an error updating the language setting!',
          }),
        )
        break
      default:
        break
    }
  }
