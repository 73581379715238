import React from 'react'
import {
  GifIcon,
  ImageIcon,
  PdfIcon,
  PlayFilledIcon,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import { MediaMarker } from './MediaMarker'

export type MediaMarkerType = 'image' | 'video' | 'pdf' | 'gif'

type MediaTypeMarkerProps = {
  type: MediaMarkerType
  label?: string
  className?: string
}

const typeToIcon = {
  image: ImageIcon,
  video: PlayFilledIcon,
  pdf: PdfIcon,
  gif: GifIcon,
}

const typeToLabel = {
  video: 'Video',
  pdf: 'PDF file',
  gif: 'GIF image',
  image: 'Image',
}

/**
 * This is a simple marker that we use on top of media items to indicate their type or other additional details.
 */
export const MediaTypeMarker = ({
  type,
  label: passedLabel,
  className,
  ...props
}: MediaTypeMarkerProps): JSX.Element | null => {
  const Icon = typeToIcon[type]
  const label = passedLabel ?? typeToLabel[type]

  return (
    <MediaMarker className={className} {...props}>
      {Icon && <Icon aria-hidden />}
      <VisuallyHidden>{label}</VisuallyHidden>
    </MediaMarker>
  )
}

MediaTypeMarker.displayName = 'MediaTypeMarker'
