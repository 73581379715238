import clsx from 'clsx'
import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import {
  AllChannelsIcon,
  IconButton,
  PlusIcon,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import { useOrganizationId } from '~publish/legacy/accountContext'
import { formatCompactNumber } from '~publish/helpers/numberFormatters'
import { usePostCounts } from '~publish/pages/AllChannels/PostList/usePostCounts'
import { allChannels, newCalendarAllChannels } from '~publish/legacy/routes'
import {
  parseQueryParams,
  type QueryParamValue,
  serializeQueryParams,
} from '~publish/hooks/useQueryParam'

import { NewPostComposerTrigger } from '../NewPostComposerTrigger'
import { SideNavItem } from './SideNavItem'

import styles from './PageSidebar.module.css'

export function AllChannelsItem(): JSX.Element | null {
  const organizationId = useOrganizationId() ?? ''
  const postCounts = usePostCounts({
    organizationId,
  })
  const url = useAllChannelsPageRoute({ keepSearch: true })

  const postQueueCounts = postCounts.counts?.queue
  const postQueueLimit = postCounts.counts?.limit

  return (
    <SideNavItem className={styles.allChannelsItem}>
      <NavLink
        data-tour-id="all-channels-menu-item"
        to={url}
        className={(isActive: boolean): string =>
          clsx(isActive && styles.selected)
        }
      >
        <div className={styles.icon}>
          <AllChannelsIcon size="small" />
        </div>
        All Channels
        <div className={styles.itemCount}>
          {formatCompactNumber(postQueueCounts, postQueueLimit)}
          <VisuallyHidden> posts</VisuallyHidden>
        </div>
        <NewPostComposerTrigger channels={[]}>
          <IconButton
            label="New Post"
            variant="secondary"
            tooltip="New post"
            size="small"
            className={styles.newPostButton}
          >
            <PlusIcon />
          </IconButton>
        </NewPostComposerTrigger>
      </NavLink>
    </SideNavItem>
  )
}

function useAllChannelsPageRoute({ keepSearch = false } = {}): string {
  const { granularity } = useParams<{ granularity: 'week' | 'month' }>()
  const currentSearch = parseQueryParams(useLocation().search)
  const currentRoute = granularity ? newCalendarAllChannels : allChannels

  const search: Record<string, QueryParamValue> = {}
  for (const { param, values: possibleValues } of currentRoute.queryParams) {
    const value = currentSearch[param]
    if (possibleValues && possibleValues.includes(value as any)) {
      search[param] = value
    } else if (!possibleValues && value) {
      search[param] = value
    }
  }

  const newRoute =
    currentRoute.buildPathname({ granularity }) +
    (keepSearch ? `?${serializeQueryParams(search)}` : '')

  return newRoute
}
