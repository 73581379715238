import { useQuery } from '@apollo/client'
import { EmptyState, CriticalIcon, Text, Notice } from '@buffer-mono/popcorn'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { AllChannelsPausedQueueNotice } from '../AllChannels/AllChannelsPausedQueueNotice'
import { ChannelProvider } from '../Channel/ChannelContext'
import { ChannelPageNotifications, GetChannelInfo } from '../Channel'
import { PageLayout } from '~publish/components/PageLayout'
import { RegisteredBannersProvider } from '~publish/components/RegisteredBanner'
import { CalendarGrid } from '~publish/legacy/calendar/components/CalendarGrid/CalendarGrid'
import { ThreadsFreeOBPaywall } from '~publish/legacy/composer/composer/components/ThreadsFreeOBPaywall'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { TimezoneProvider } from '~publish/hooks/useTimezone'
import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'
import { getFragmentData } from '~publish/gql'
import { FilterByChannel_Channel } from '~publish/components/FilterByChannel'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

import { CalendarHeader } from './CalendarHeader/CalendarHeader'
import styles from './CalendarPage.module.css'
import { useCalendarAnalytics } from './hooks/useCalendarAnalytics'
import { useCalendarState } from './hooks/useCalendarState'
import isViewOnly from './hooks/useIsViewOnly'
import { useTwentyFourHourTimeFormat } from './hooks/useTwentyFourHourTimeFormat'
import { NewCalendar } from './NewCalendar'

const CalendarPage = (): JSX.Element => {
  const [isTimezonedCalendarEnabled] = usePublishRevamp()
  const calendarState = useCalendarState()
  const { filters, navigation, postsQuery } = calendarState
  const { granularity } = navigation
  const { channelsFilter, tagsFilter, tags, channels, error, isSingleChannel } =
    filters

  const hasTwentyFourHourTimeFormat = useTwentyFourHourTimeFormat()

  const organizationId = useOrganizationId() ?? ''
  const { onDropPostSuccess, onDropPostError } = useCalendarAnalytics({
    granularity: navigation.granularity,
    channelsCount: channelsFilter.sanitized.length,
    organizationId,
  })
  const params = useParams<{ id: string | undefined }>()
  const viewOnly =
    !postsQuery.loading &&
    isViewOnly({
      channelsSelected: channelsFilter.sanitized,
      listOfChannels: channels,
    })

  const [openModal, setOpenModal] = useState({
    open: false,
    ctaString: '',
    service: '',
  })

  const hasPausedQueues = channels.some((channel) => channel.isQueuePaused)
  const channelFound = channels.find((channel) => channel.id === params.id)
  const channel = channelFound
    ? getFragmentData(FilterByChannel_Channel, channelFound)
    : undefined

  if (error) {
    return (
      <EmptyState size="medium" variant="critical">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Failed to load</EmptyState.Heading>
        <EmptyState.Description>
          Error happened, please let our team know about it.{' '}
          <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  return (
    <TimezoneProvider timeZone={channel?.timezone ?? getCurrentTimeZone()}>
      <CalendarProviders>
        <PageLayout className={styles.layout}>
          {openModal?.open && (
            <PageLayout.Container>
              <ThreadsFreeOBPaywall
                closeFirstModal={(): void =>
                  setOpenModal({ open: false, ctaString: '', service: '' })
                }
                ctaString={openModal.ctaString}
                service={openModal.service}
              />
            </PageLayout.Container>
          )}
          <PageLayout.NotificationsContainer>
            {isSingleChannel ? (
              <ChannelPageNotifications />
            ) : (
              hasPausedQueues && <AllChannelsPausedQueueNotice />
            )}
            {viewOnly && (
              <Notice variant="warning" className={styles.CalendarNotice}>
                Looks like you don’t have full posting permissions for all
                channels. Visit individual Queues to create or manage posts.
              </Notice>
            )}
          </PageLayout.NotificationsContainer>
          <CalendarHeader
            tags={tags}
            isSingleChannel={isSingleChannel}
            selectedTagIds={tagsFilter.sanitized}
            onSelectTags={tagsFilter.onSelect}
            selectedChannelIds={channelsFilter.sanitized}
            channels={channels}
            onSelectChannels={channelsFilter.onSelect}
          />

          <PageLayout.Container
            size="full"
            className={styles.calendarContainer}
          >
            {isTimezonedCalendarEnabled ? (
              <NewCalendar
                hasTwentyFourHourTimeFormat={hasTwentyFourHourTimeFormat}
                setOpenModal={setOpenModal}
                isViewOnly={viewOnly}
                selectedChannelIds={channelsFilter.sanitized}
                postsQuery={postsQuery}
              />
            ) : (
              <CalendarGrid
                hasTwentyFourHourTimeFormat={hasTwentyFourHourTimeFormat}
                onDropPostSuccess={onDropPostSuccess}
                onDropPostError={onDropPostError}
                setOpenModal={setOpenModal}
                isViewOnly={viewOnly}
                navigation={navigation}
                granularity={granularity}
                channelsFilter={channelsFilter}
                postsQuery={postsQuery}
              />
            )}
          </PageLayout.Container>
        </PageLayout>
      </CalendarProviders>
    </TimezoneProvider>
  )
}

export default CalendarPage

function CalendarProviders({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const calendarState = useCalendarState()
  const {
    filters: { isSingleChannel },
  } = calendarState

  if (isSingleChannel) {
    return <SingleChannelProvider>{children}</SingleChannelProvider>
  }

  return (
    <RegisteredBannersProvider
      key={`registered-notice-provider-allChannels-calendar`}
    >
      {children}
    </RegisteredBannersProvider>
  )
}

function SingleChannelProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const organizationId = useOrganizationId() ?? ''
  const id = useParams<{ id: string }>().id

  const { data } = useQuery(GetChannelInfo, {
    variables: { organizationId, channelId: id },
    fetchPolicy: 'cache-and-network',
  })

  const channel = data?.channel
  if (!channel) {
    return <></>
  }
  return (
    <ChannelProvider channel={channel}>
      <TimezoneProvider timeZone={channel.timezone}>
        <RegisteredBannersProvider
          key={`registered-banner-provider-${channel.name}-calendar`}
        >
          {children}
        </RegisteredBannersProvider>
      </TimezoneProvider>
    </ChannelProvider>
  )
}
