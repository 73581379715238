import React from 'react'
import PropTypes from 'prop-types'

import {
  BufferTopIcon,
  BufferMiddleIcon,
  BufferBottomIcon,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
} from '@bufferapp/components/lib/Icon/Icons'

// @ts-expect-error TS(7031) FIXME: Binding element 'dark' implicitly has an 'any' typ... Remove this comment to see the full error message
const loadingStyle = ({ dark }): React.CSSProperties => ({
  width: '100%',
  height: '100%',
  background: dark ? 'rgba(50,59,67,0.8)' : 'white',
  zIndex: 12000,
  textAlign: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
})

// @ts-expect-error TS(7031) FIXME: Binding element 'size' implicitly has an 'any' typ... Remove this comment to see the full error message
const getIconContainerStyle = ({ size, fullscreen }): React.CSSProperties => ({
  display: 'inline-block',
  width: `${size}px`,
  height: `${size}px`,
  position: fullscreen ? 'absolute' : 'relative',
  top: fullscreen ? '50%' : 'auto',
  left: fullscreen ? '50%' : 'auto',
  margin: fullscreen ? `-${size / 2}px 0 0 -${size / 2}px` : 0,
})

// @ts-expect-error TS(7006) FIXME: Parameter 'delay' implicitly has an 'any' type.
const getIconPartStyle = (delay): React.CSSProperties => ({
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  animation: `2s ${delay} fade infinite`,
})

// @ts-expect-error TS(7031) FIXME: Binding element 'fullscreen' implicitly has an 'an... Remove this comment to see the full error message
const BufferLoading = ({ fullscreen, size, dark }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ width: string; height: string; background:... Remove this comment to see the full error message
  <div style={fullscreen ? loadingStyle({ dark }) : null}>
    <div style={getIconContainerStyle({ size, fullscreen })}>
      <div style={getIconPartStyle('0ms')}>
        <BufferTopIcon
          color={dark ? 'white' : 'shuttleGray'}
          size={{ width: `${size}`, height: `${size}` }}
        />
      </div>
      <div style={getIconPartStyle('150ms')}>
        <BufferMiddleIcon
          color={dark ? 'white' : 'shuttleGray'}
          size={{ width: `${size}`, height: `${size}` }}
        />
      </div>
      <div style={getIconPartStyle('300ms')}>
        <BufferBottomIcon
          color={dark ? 'white' : 'shuttleGray'}
          size={{ width: `${size}`, height: `${size}` }}
        />
      </div>
    </div>
  </div>
)

BufferLoading.propTypes = {
  fullscreen: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  dark: PropTypes.bool,
}

BufferLoading.defaultProps = {
  fullscreen: false,
  size: 100,
  dark: false,
}

export default BufferLoading
