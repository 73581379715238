import React, { useState } from 'react'
import { store, useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'
import { PostTypeStory } from '~publish/legacy/post/constants'
import { gifsAllowed } from '../uploadcare/constants'
import CharacterHashtagCount from '../composer/composer/components/CharacterHashtagCount'
import AddThread from '../composer/composer/components/AddThread'
import { IntegrationsBar } from './IntegrationsBar'
import { useIntegrationServices } from './useIntegrationServices'
import { AIAssistantTrigger } from '~publish/legacy/ai/components/Triggers/AIAssistantTrigger'
import type { Draft } from '../composer/composer/entities/Draft'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'

import {
  IntegrationsWrapper,
  Emoji,
  LeftWrapper,
  RightWrapper,
  VerticalDivider,
  IconWrapper,
  ShortLinkNoticeWrapper,
} from './styles'
import { HashTagManagerButton } from './components/HashTagManagerButon'
import {
  toggleAIAssistant,
  setAIAssistantPlacement,
} from '~publish/legacy/ai/state/slice'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import { LinkShortenerButton } from '~publish/legacy/integrations-bar/components/LinkShortenerButton'
import { CloseIcon, Notice as PopcornNotice } from '@buffer-mono/popcorn'
import { getCurrentProfile } from '~publish/legacy/composer/composer/components/app/AppStateless'
import { handleUploadError } from '../composer/composer/action-creators/handleUpdloadError'
import { useComposerUploader } from '../composer/composer/hooks/useComposerUploader'
import { selectPendingUploads } from '../uploads/state/selectors'

/**
 *
 */
export const ComposerIntegrationsBar = ({
  draft,
  emojiContainer = null,
}: {
  draft: Draft
  emojiContainer: React.ReactElement | null
}): JSX.Element => {
  const { onFileReady, uploader } = useComposerUploader({ draft })

  const context = 'publishComposer'

  const dispatch = useAppDispatch()
  const uploadsInProgress = useAppSelector((state) =>
    selectPendingUploads(state, uploader.getId()),
  )

  const shouldShowGiphyButton =
    gifsAllowed.includes(draft.service.name) &&
    !draft.isFacebookReelPost() &&
    !draft.isReelsPost()

  const shouldShowUnsplashButton =
    !!(draft?.service?.name !== Service.Youtube) &&
    !draft.isReelsPost() &&
    !draft.isFacebookReelPost()

  const shouldShowCanvaButton =
    !draft.isVerticalVideoOnlySupported() && !draft.isFacebookReelPost()

  // call to the hook that returns services configuration for the context
  const { services, activeService } = useIntegrationServices({
    context,
    onFileReady,
    updateType: draft.isStoryPost()
      ? PostTypeStory
      : draft.updateType ?? undefined, // only used for uploadCareOptions to make differences in case of being PostTypeStory
    serviceName: draft.isStoryPost() ? 'instagramStory' : draft.service.name,
    onError: handleUploadError,
    enableCanva: shouldShowCanvaButton,
    enableGiphy: shouldShowGiphyButton,
    enableUnsplash: shouldShowUnsplashButton,
  })

  const remainingImages = draft.getTotalImageUploadsRemaining()
  const isOverTheLimit = uploadsInProgress.length >= remainingImages
  const disableBar = !draft.canAttachMoreMedia() || isOverTheLimit

  const onToggleAIAssistant = (): void => {
    // Sets the placement (source) for tracking
    // Must be set before the upgrade modal is triggered as the modal
    // relies on the placement for tracking.
    dispatch(setAIAssistantPlacement({ placement: 'publishComposer' }))

    // Display a upgrade flow for multi-product users
    // Restrict AI Assistant feature to New Buffer users only
    if (selectShouldShowNBMigration(store.getState())) {
      ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
        ctaButton: 'integrationsBar',
      })
      return
    }

    dispatch(toggleAIAssistant(true))

    ComposerActionCreators.updateSidebarOnTop(null, 'aiassistant')
  }

  const selectedProfile = getCurrentProfile()

  const showLinkShortenerButton =
    draft.service.isShorteningLinksAvailable && draft.canType()

  const [shortLinkMessageDismissed, setShortlinkMessageDismissed] = useState(
    !!localStorage.getItem('short_link_message_dismissed'),
  )

  const hideShortLinkMessage = (): void => {
    localStorage.setItem('short_link_message_dismissed', 'true')
    setShortlinkMessageDismissed(true)
  }

  return (
    <>
      {!shortLinkMessageDismissed &&
        showLinkShortenerButton &&
        draft.shouldShowShortLinkMessage() && (
          <ShortLinkNoticeWrapper>
            <PopcornNotice
              variant="warning"
              className={'notice-link-too-short'}
            >
              <PopcornNotice.Text>
                This link is too short for further shortening{' '}
                <IconWrapper>
                  <CloseIcon onClick={hideShortLinkMessage} size="small" />
                </IconWrapper>
              </PopcornNotice.Text>
            </PopcornNotice>
          </ShortLinkNoticeWrapper>
        )}
      <IntegrationsWrapper className="boxed">
        <IntegrationsBar
          context={context}
          services={services}
          activeService={activeService}
          disableBar={disableBar}
        >
          <LeftWrapper>
            {draft.canType() && (
              <>
                <VerticalDivider />
                <Emoji data-tip="Add Emoji">{emojiContainer}</Emoji>
              </>
            )}
            <HashTagManagerButton draft={draft} />
            {showLinkShortenerButton && selectedProfile && (
              <LinkShortenerButton
                draft={draft}
                selectedProfileId={selectedProfile.id}
                domainForLinkShorteningSelectedProfile={
                  selectedProfile.shorteningDomain
                }
              />
            )}
            {draft.canType() && (
              <>
                <VerticalDivider />
                <AIAssistantTrigger onClick={onToggleAIAssistant} />
              </>
            )}
          </LeftWrapper>
          <RightWrapper>
            <CharacterHashtagCount draft={draft} />
            <AddThread draft={draft} />
          </RightWrapper>
        </IntegrationsBar>
      </IntegrationsWrapper>
    </>
  )
}
