import dayjs, { type Dayjs } from 'dayjs'
import { EventEmitter } from 'events'
import partition from 'lodash/partition'
import { AppEnvironments } from '~publish/legacy/constants'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { selectHasProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { selectImageDimensionsKey } from '~publish/legacy/user/selectors'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import AppActionCreators from '../action-creators/AppActionCreators'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import NotificationActionCreators from '../action-creators/NotificationActionCreators'
import {
  AsyncOperationStates,
  COMPOSER_ERROR_CODE,
  NotificationScopes,
  QueueingTypes,
  Services,
} from '../AppConstants'
import AppDispatcher from '../dispatcher'
import {
  getNewProfile,
  getNewProfileGroup,
  getNewSubprofile,
  getNewUserData,
} from '../entities/factories'
import { ActionTypes } from '../state/ActionTypes'
import ComposerStore from './ComposerStore'

// import { registerStore, sendToMonitor } from '../utils/devtools';
import type {
  ComposerProfile,
  Subprofile,
  ValidationCode,
} from '~publish/legacy/composer/composer/stores/types'
import type { ServiceDefinition } from '~publish/legacy/constants/services/types'
import { isNotUndefined } from '~publish/legacy/utils/isNotUndefined/isNotUndefined'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import type { ComposerDispatchPayload } from '../../interfaces/types'
import type { Draft } from '../entities/Draft'
import type { FormattedProfileData } from '../entities/types'
import { getWiredComposerStore } from '../state/getWiredComposerStore'
import type {
  AppState,
  AppStoreMetaData,
  AppStoreOptions,
  AppStoreState,
  ComposerProfileGroup,
  ComposerUserData,
  DayTimeslots,
  OrganizationsData,
  Timeslot,
  ValidationFeedback,
} from './types'

const store = getWiredComposerStore()

const CHANGE_EVENT = 'change'

const getInitialState = (): AppStoreState => ({
  profiles: [], // Data structure in getNewProfile()

  organizationsData: {},

  // Describe the application state
  appState: {
    isLoaded: false,
    expandedComposerId: null,
    expandedProfileSubprofileDropdownId: null,
    isDraftsSavePending: false,
    draftSaveQueueingType: null,
    isThumbnailLoading: false,
    isSavingPossible: false,
    isSavingDraftPossible: false,
    whatPreventsSaving: [], // Data structure in getNewPreventsSavingObj()
    whatPreventsSavingDraft: [],
    composersWhichHaveBeenCollapsed: new Set(),
    composersHaveBeenExpanded: false,
    // Ids of profiles whose subprofile dropdowns we want to auto-expand
    profileSubprofileDropdownsIdsToExpand: [],
    // true if enabled, false if enabled then disabled thru "customize" button, null if unused
    isOmniboxEnabled: null,
    // Profile ids that were already saved from drafts that failed saving to all profiles
    partiallySavedDraftsProfilesIds: new Map(), // draft id <=> [savedProfileIds]
    domainsOwnedByFacebookPages: new Map(), // url <=> (profile id <=> isOwned)

    // Only show this notif once per session if soft-closed
    wasTwitterMaxOneProfileSelectedNotifClosedOnce: false,
    composerSidebarVisible: false,
    composerLeftSidebarVisible: false,
    composerPostPreviewVisible: false,
    connectChannelPopoverVisible: false,
    rightSidePanelVisibleOnTop: null,
    composerTransitionFinished: false,
  },

  userData: {}, // Data structure in getNewUserData()
  metaData: {},
  options: {},
})

let state = getInitialState()

// Register with Redux DevTools (uncomment to enable)
// registerStore('app', state);

const isExtension = (): boolean =>
  state.metaData.appEnvironment !== AppEnvironments.WEB_DASHBOARD &&
  state.metaData.appEnvironment !== AppEnvironments.CAMPAIGNS &&
  state.metaData.appEnvironment !== AppEnvironments.CALENDAR

const AppStore = {
  ...EventEmitter.prototype,
  emitChange: (): boolean => AppStore.emit(CHANGE_EVENT),
  addChangeListener: (callback: (args?: unknown) => void): EventEmitter =>
    AppStore.on(CHANGE_EVENT, callback),
  removeChangeListener: (callback: (args?: unknown) => void): EventEmitter =>
    AppStore.removeListener(CHANGE_EVENT, callback),
  isExtension: (): boolean => isExtension(),
  getAppState: (): AppState => state.appState,
  getUserData: (): ComposerUserData | Record<string, never> => state.userData,
  getOrganizationsData: (): OrganizationsData | Record<string, never> =>
    state.organizationsData,
  getMetaData: (): AppStoreMetaData | Record<string, never> => state.metaData,
  getOptions: (): AppStoreOptions | Record<string, never> => state.options,
  getImageDimensionsKey: (): string =>
    selectImageDimensionsKey(store.getState()),
  getOrganizationId: (): string =>
    selectCurrentOrganizationId(store.getState()),
  hasProfiles: (): boolean => selectHasProfiles(store.getState()),

  // Get all profiles if no param passed, or profiles matching a passed array of ids
  getProfiles: (ids?: string[]): ComposerProfile[] => {
    let profiles

    if (!ids) {
      profiles = state.profiles
    } else {
      profiles = state.profiles
        .filter((profile) => ids.includes(profile.id))
        .sort((profileA, profileB) =>
          ids.indexOf(profileA.id) < ids.indexOf(profileB.id) ? -1 : 1,
        )
    }

    return profiles
  },

  isTransitionFinished: (): boolean => {
    return state.appState.composerTransitionFinished
  },

  whatPreventsSaving: (): ValidationFeedback[] =>
    state.appState.whatPreventsSaving,

  getProfileIds: (): string[] =>
    AppStore.getProfiles().map((profile) => profile.id),

  getProfilesForService: (serviceName?: string | null): ComposerProfile[] =>
    AppStore.getProfiles().filter(
      (profile) => profile.service.name === serviceName,
    ),

  getSelectedProfiles: (): ComposerProfile[] =>
    state.profiles.filter((profile) => profile.isSelected),

  hasProfilesSelected: (): boolean =>
    state.profiles.some((profile) => profile.isSelected),

  hasPinterestBoardsSelected: (): boolean => {
    const selectedPinterestProfiles = state.profiles.filter(
      (profile) => profile.isSelected && profile.service.name === 'pinterest',
    )

    if (selectedPinterestProfiles.length === 0) {
      return true
    }

    return selectedPinterestProfiles
      .map((profile) => !!profile.selectedSubprofileId)
      .reduce((prev, current) => prev || current)
  },

  hasFBDraftWithNoText: (): boolean => {
    const emptyFacebookDrafts = ComposerStore.getEnabledDrafts().filter(
      (draft) => draft.id === 'facebook' && draft.text.length < 1,
    )
    return emptyFacebookDrafts.length > 0
  },

  getSelectedProfilesForService: (serviceName: string): ComposerProfile[] =>
    AppStore.getSelectedProfiles().filter(
      (profile) => profile.service.name === serviceName,
    ),

  getProfile: (id: string): ComposerProfile | undefined =>
    state.profiles.find((profile) => profile.id === id),

  getProfileGroup: (id: string): ComposerProfileGroup | undefined =>
    state.userData.profileGroups.find((group) => group.id === id),

  getServicesWithSelectedProfiles: (): ServiceDefinition[] => {
    const selectedProfilesServicesNames = AppStore.getSelectedProfiles().map(
      (profile) => profile.service.name,
    )

    const dedupedServicesNames = [...new Set(selectedProfilesServicesNames)]
    const selectedServices = dedupedServicesNames
      .map((serviceName) => Services.get(serviceName))
      .filter(isNotUndefined)

    return selectedServices
  },

  /**
   * Returns:
   * - false if slot picking isn't available
   * - undefined if slots data for that day isn't available (yet)
   * - an array of slots for that day (empty if no slots)
   */
  getAvailableSchedulesSlotsForDay: (
    timestamp: number,
  ): false | undefined | Timeslot[] => {
    const selectedProfiles = AppStore.getSelectedProfiles()
    const isSlotPickingAvailable = selectedProfiles.length === 1
    if (!isSlotPickingAvailable) return false

    const { id: profileId } = selectedProfiles[0]
    // eslint-disable-next-line no-use-before-define
    const localDateMoment = getProfileLocalTimeMoment(profileId, timestamp)

    // eslint-disable-next-line no-use-before-define
    return getProfileSlotsForDay(profileId, localDateMoment)
  },

  getComposerSidebarVisibility: (): boolean =>
    state.appState.composerSidebarVisible,
  getComposerLeftSidebarVisibility: (): boolean =>
    state.appState.composerLeftSidebarVisible,
  getSidebarVisibleOnTop: (): string | null =>
    state.appState.rightSidePanelVisibleOnTop,
  getComposerPostPreviewVisibility: (): boolean =>
    state.appState.composerPostPreviewVisible,
  getConnectChannelPopoverVisibility: (): boolean =>
    state.appState.connectChannelPopoverVisible,
  getExpandedComposerId: (): string | null =>
    state.appState.isOmniboxEnabled
      ? 'omni'
      : state.appState.expandedComposerId,
  setExpandedComposerId: (id: string | null): void => {
    setExpandedComposerId(id)
  },
  areAllSelectedProfilesIG: (): boolean => {
    const notInstagram = AppStore.getSelectedProfiles().some(
      (profile) => profile.service.name !== 'instagram',
    )

    return !notInstagram || AppStore.getExpandedComposerId() === 'instagram'
  },
  getComposerFeedbackMessages: (draft: Draft): ValidationFeedback[] => {
    // Message codes for which feedback shouldn't be displayed inside the composer
    const whatPreventsSavingIgnoredCodes: ValidationCode[] = [
      VALIDATION_CODE.CHARACTER_LIMIT_REACHED,
    ]
    const draftMode = state.metaData?.draftMode ?? false
    const whatPreventsSaving = draftMode
      ? state.appState.whatPreventsSavingDraft
      : state.appState.whatPreventsSaving

    return whatPreventsSaving.filter(
      (what) =>
        what.composerId === draft.id &&
        what.code &&
        !whatPreventsSavingIgnoredCodes.includes(what.code),
    )
  },

  /**
   * Please don't use it, it should be an entitlement
   * @deprecated
   */
  isSelectedOrganizationOnFreePlan: (): boolean => {
    return state.organizationsData?.selected?.plan?.toLowerCase() === 'free'
  },
}

// Checks whether a profile already exists, to prevent duplication
const isProfileUnique = (profileData: { id: string }): boolean =>
  state.profiles.every((profile) => profile.id !== profileData.id)

const createProfiles = (profilesData: FormattedProfileData[]): void => {
  profilesData.forEach((profileData) => {
    if (isProfileUnique(profileData))
      state.profiles.push(getNewProfile(profileData))
  })
}

const createProfileGroup = (groupData: ComposerProfileGroup): number =>
  state.userData.profileGroups.push(getNewProfileGroup(groupData))

const updateProfileGroup = ({
  id,
  name,
  profileIds,
}: {
  id: string
  name: string
  profileIds: string[]
}): void => {
  const groupToUpdate = state.userData.profileGroups.find(
    (group) => group.id === id,
  )

  if (groupToUpdate) {
    Object.assign(groupToUpdate, {
      name,
      profileIds,
    })
  }
}

const deleteProfileGroup = ({ id }: { id: string }): void => {
  const index = state.userData.profileGroups.findIndex(
    (group) => group.id === id,
  )
  state.userData.profileGroups.splice(index, 1)
}

const setDraftsSavingState = (
  savingState: keyof typeof AsyncOperationStates,
  queueingType: keyof typeof QueueingTypes = QueueingTypes.QUEUE,
): void => {
  switch (savingState) {
    case AsyncOperationStates.PENDING:
      state.appState.isDraftsSavePending = true
      state.appState.draftSaveQueueingType = queueingType
      break

    case AsyncOperationStates.DONE:
      state.appState.isDraftsSavePending = false
      break

    default:
      break
  }
}

// Auto-expand composer when only 1 profile is selected
const maybeAutoExpandComposer = (): void => {
  const activeServices = AppStore.getServicesWithSelectedProfiles()
  const hasOneActiveService = activeServices.length === 1
  if (!hasOneActiveService) return

  const activeService = activeServices[0]
  const composerId = activeService.name
  ComposerActionCreators.expand(composerId)
}

const updateShortenerForProfile = (profileId: string, domain: string): void => {
  const profile = AppStore.getProfile(profileId)
  if (!profile) {
    return
  }
  profile.shorteningDomain = domain

  state.profiles = state.profiles.map((p) => (p.id === profileId ? profile : p))

  AppActionCreators.markAppAsLoaded()
}

const refreshFacebookDomainOwnershipData = (): void => {
  if (!state.appState.isLoaded) return

  // Don't check more than 5 pages to save on Facebook API request quota
  const maxPagesToCheckCount = 5

  if (!ComposerStore.isDraftEnabled('facebook')) return
  if (!ComposerStore.doesDraftHaveLinkAttachmentEnabled('facebook')) return

  const selectedFacebookProfiles =
    AppStore.getSelectedProfilesForService('facebook')
  const selectedFacebookPages = selectedFacebookProfiles.filter(
    (profile) => profile.serviceType === 'page',
  )
  if (selectedFacebookPages.length > maxPagesToCheckCount) return

  const selectedPages = selectedFacebookPages.slice(0, maxPagesToCheckCount)
  if (selectedPages.length === 0) return

  const linkUrl = ComposerStore.getDraftLinkUrl('facebook')

  const domainOwnershipPerProfile =
    state.appState.domainsOwnedByFacebookPages.get(linkUrl) || new Map()

  const pendingRequestsCountForDomain = Array.from(
    domainOwnershipPerProfile.values(),
  ).filter((isOwner) => isOwner === null).length
  if (pendingRequestsCountForDomain >= maxPagesToCheckCount) return

  const selectedProfilesToQueryFacebookForOwnership = selectedPages.filter(
    ({ id }) => domainOwnershipPerProfile.get(id) === undefined,
  )

  selectedProfilesToQueryFacebookForOwnership.forEach(({ id }) => {
    // eslint-disable-next-line no-use-before-define
    updateFacebookDomainOwnershipForPage(id, linkUrl, false)

    AppActionCreators.getFacebookDomainOwnershipForProfile(id, linkUrl)
  })
}

/**
 * Select profiles in place of user, by either opening dropdown for subprofile-enabled
 * networks, or actually selecting the profile straight away for other networks.
 */
const selectProfilesOnBehalfOfUser = (
  ids: string[],
  markAppAsLoadedWhenDone = false,
  originatedFromGroupSelection = false,
): void => {
  const profilesToSelect = AppStore.getProfiles(ids)

  const [profilesWithSubprofiles, profilesWithoutSubprofiles]: [
    ComposerProfile[],
    ComposerProfile[],
  ] = partition(
    profilesToSelect,
    (profile: ComposerProfile) => profile.subprofiles.length > 0,
  )

  const hasProfilesWithoutSubprofiles = profilesWithoutSubprofiles.length > 0
  const hasProfilesWithSubprofiles = profilesWithSubprofiles.length > 0

  if (hasProfilesWithoutSubprofiles) {
    const profilesIdsWithoutSubprofiles = profilesWithoutSubprofiles.map(
      (profile) => profile.id,
    )
    AppActionCreators.selectProfiles(
      profilesIdsWithoutSubprofiles,
      markAppAsLoadedWhenDone,
      originatedFromGroupSelection,
    )
  } else if (markAppAsLoadedWhenDone) {
    AppActionCreators.markAppAsLoaded()
  }

  if (hasProfilesWithSubprofiles) {
    const draft = ComposerStore.getDraft('pinterest')

    if (draft?.isEmpty()) {
      profilesWithSubprofiles.forEach((profile) => {
        AppActionCreators.selectSubprofile(
          profile.id,
          profile.subprofiles[0].id,
        )
        AppActionCreators.collapseProfileSubprofileDropdown(profile.id)
        return profile.id
      })
    }
  }

  // we need to set isTwitterPremium flag on twitter drafts for increased char limit
  // to work, as it checks draft.isTwitterPremium to determine it
  const twitterProfile = profilesToSelect?.find(
    (profile) => profile.service.name === 'twitter',
  )
  if (twitterProfile) {
    ComposerActionCreators.setIsTwitterPremium(
      'twitter',
      twitterProfile.isTwitterPremium ?? false,
    )
  }
}

const selectProfile = (
  id: string,
  markAppAsLoadedWhenDone = false,
  originatedFromGroupSelection = false,
): void => {
  const profile = AppStore.getProfile(id)

  if (!profile) return

  if (profile && profile.isSelected) {
    if (markAppAsLoadedWhenDone) AppActionCreators.markAppAsLoaded()
    return
  }
  if (profile.isDisabled) return

  const { enableTwitterChanges } = AppStore.getMetaData()

  // Select profile
  profile.isSelected = true

  // Enable composer if the selected profile is the first selected for that service
  const serviceName = profile.service.name
  const selectedProfilesForService =
    AppStore.getSelectedProfilesForService(serviceName)

  if (selectedProfilesForService.length === 1) {
    const composerId = serviceName
    ComposerActionCreators.enable(composerId, markAppAsLoadedWhenDone)
    ComposerActionCreators.parseDraftTextLinks(composerId)
  } else if (markAppAsLoadedWhenDone) {
    AppActionCreators.markAppAsLoaded()
  }

  if (serviceName === 'twitter') {
    ComposerActionCreators.setIsTwitterPremium(
      serviceName,
      !!profile.isTwitterPremium,
    )
  }

  // Unselect previously selected profile if we're dealing with Twitter
  if (enableTwitterChanges) {
    if (serviceName === 'twitter' && selectedProfilesForService.length === 2) {
      const previousProfile = selectedProfilesForService.find(
        (p) => p.id !== id,
      )
      if (previousProfile) previousProfile.isSelected = false

      // Show max once per session
      if (!state.appState.wasTwitterMaxOneProfileSelectedNotifClosedOnce) {
        const notifScope = NotificationScopes.TWITTER_MAX_ONE_PROFILE_SELECTED
        const notifMessage = originatedFromGroupSelection
          ? `Due to <a href="https://support.buffer.com/article/561-using-twitter-with-buffer?${HC_UTM_PARAMS}#h_01H6SJ579KHBTCNRKRVSNXMVWV" target="_blank">recent changes with Twitter</a>, you're only able to post to one Twitter account at a time. <b>So we've only selected ${profile.service.formattedUsername} Twitter account from your group. <a href="/app/edit_groups" target="_blank">Click here to edit your group.</a></b>`
          : `Due to recent changes with Twitter / X, you're only able to post to one Twitter / X account at a time, so <b>you are only posting to ${profile.service.formattedUsername}</b>. <a href="https://support.buffer.com/article/561-using-twitter-with-buffer?${HC_UTM_PARAMS}#h_01H6SJ579KHBTCNRKRVSNXMVWV" target="_blank">Learn more about the changes</a>.`

        NotificationActionCreators.removeAllNotificationsByScope(notifScope)
        NotificationActionCreators.queueInfo({
          scope: notifScope,
          message: notifMessage,
          onlyCloseOnce: true,
          showSoftAndHardCloseOptions: true,
        })
      }
    }
  }

  if (serviceName === 'facebook') {
    const editor = ComposerStore.getDraft(serviceName)?.editorState
    if (editor) editor.areMentionsSupported?.(editor)
    refreshFacebookDomainOwnershipData()
  }
  if (serviceName === 'instagram') ComposerActionCreators.updateInstagramState()
}

const unselectProfile = (id: string): void => {
  const profile = AppStore.getProfile(id)
  if (!profile?.isSelected) return

  const { enableTwitterChanges } = AppStore.getMetaData()

  // Unselect profile
  profile.isSelected = false

  // Unselect subprofile if any (profiles can be unselected via unselectSubprofile() or directly)
  profile.selectedSubprofileId = null

  // Disable composer if the unselected profile was the last selected for that service
  const serviceName = profile.service.name
  const selectedProfilesCountForService =
    AppStore.getSelectedProfilesForService(serviceName).length

  if (selectedProfilesCountForService === 0) {
    const composerId = serviceName
    ComposerActionCreators.disable(composerId)

    if (enableTwitterChanges) {
      if (serviceName === 'twitter') {
        const notifScope = NotificationScopes.TWITTER_MAX_ONE_PROFILE_SELECTED
        NotificationActionCreators.removeAllNotificationsByScope(notifScope)
      }
    }
  }

  maybeAutoExpandComposer()

  if (serviceName === 'facebook') refreshFacebookDomainOwnershipData()
  if (serviceName === 'instagram') ComposerActionCreators.updateInstagramState()
}

const selectSubprofile = (profileId: string, subprofileId: string): void => {
  const profile = AppStore.getProfile(profileId)

  if (profile) {
    profile.selectedSubprofileId = subprofileId
    AppActionCreators.selectProfile(profile.id)
  }
}

const selectSubprofiles = (
  idsMap: Map<string, string>,
  markAppAsLoadedWhenDone = false,
): void => {
  const profileIds: string[] = []

  idsMap.forEach((subprofileId, profileId) => {
    const profile = AppStore.getProfile(profileId)
    if (profile) profile.selectedSubprofileId = subprofileId

    profileIds.push(profileId)
  })

  AppActionCreators.selectProfiles(profileIds, markAppAsLoadedWhenDone)
}

const unselectSubprofile = (profileId: string): void => {
  const profile = AppStore.getProfile(profileId)

  if (profile) {
    profile.selectedSubprofileId = null
    AppActionCreators.unselectProfile(profile.id)
  }
}

const preventSavingMessages = {
  AT_LEAST_ONE_PROFILE: 'At least one profile should be selected',
  AT_LEAST_ONE_BOARD: 'Select Pinterest board to post to',
  WAITING_FOR_THUMBNAIL: 'Waiting for thumbnail to save...',
  NO_MORE_UPDATES_TO_SAVE: 'No more updates to be saved',
  SCHEDULE_IN_THE_PAST: 'The scheduled time seems to be in the past',
}

export interface CheckIfSavingPossibleResult {
  isSavingPossible: boolean
  isSavingDraftPossible: boolean
  whatPreventsSaving?: ValidationFeedback[]
  whatPreventsSavingDraft?: ValidationFeedback[]
}

// List of conditions to pass in order to be able to save the updates
// For perf reasons all the checks aren't run all the time, we try to fail as early
// as possible, though in some situations it's useful to have multiple checks run
// to provide more complete feedback.
const checkIfSavingPossible = (): CheckIfSavingPossibleResult => {
  const savingNotPossible = {
    isSavingPossible: false,
    isSavingDraftPossible: false,
  }

  // At least one profile should be selected
  if (!AppStore.hasProfilesSelected()) {
    const whatPreventsSaving = [
      {
        code: COMPOSER_ERROR_CODE.MISSING_PROFILE,
        message: preventSavingMessages.AT_LEAST_ONE_PROFILE,
        composerId: '',
      },
    ]
    return {
      ...savingNotPossible,
      whatPreventsSaving,
      whatPreventsSavingDraft: whatPreventsSaving,
    }
  }

  if (!AppStore.hasPinterestBoardsSelected()) {
    const whatPreventsSaving = [
      {
        code: COMPOSER_ERROR_CODE.MISSING_PROFILE,
        message: preventSavingMessages.AT_LEAST_ONE_BOARD,
        composerId: '',
      },
    ]
    return {
      ...savingNotPossible,
      whatPreventsSaving,
      whatPreventsSavingDraft: whatPreventsSaving,
    }
  }

  // Updates shouldn't be in the process of being saved
  if (state.appState.isDraftsSavePending) {
    return {
      ...savingNotPossible,
    }
  }
  // Updates shouldn't be saved while Instagram thumbnail is uploading
  if (state.appState.isThumbnailLoading) {
    const whatPreventsSaving = [
      {
        code: COMPOSER_ERROR_CODE.PROCESSING,
        message: preventSavingMessages.WAITING_FOR_THUMBNAIL,
        composerId: '',
      },
    ]
    return {
      ...savingNotPossible,
      whatPreventsSaving,
      whatPreventsSavingDraft: whatPreventsSaving,
    }
  }

  // Updates shouldn't have been saved already
  if (ComposerStore.areAllDraftsSaved()) {
    const whatPreventsSaving = [
      {
        code: COMPOSER_ERROR_CODE.ALREADY_SAVED,
        message: preventSavingMessages.NO_MORE_UPDATES_TO_SAVE,
        composerId: '',
      },
    ]
    return {
      ...savingNotPossible,
      whatPreventsSaving,
      whatPreventsSavingDraft: whatPreventsSaving,
    }
  }

  // Enabled posts shouldn't be empty
  const invalidEnabledPostsInfo = ComposerStore.getInvalidEnabledDraftsFeedback(
    { isDraft: false },
  )
  const invalidEnabledDraftsInfo =
    ComposerStore.getInvalidEnabledDraftsFeedback({ isDraft: true })

  if (hasMultipleFacebookChannelsSelected()) {
    const whatPreventsSaving = invalidEnabledPostsInfo
    const whatPreventsSavingDraft = invalidEnabledDraftsInfo

    whatPreventsSaving.push({
      message:
        'You cannot schedule a post for Facebook Pages and Facebook Groups at the same time.',
      composerId: '',
      code: VALIDATION_CODE.FACEBOOK_DIFFERENT_PROFILE_TYPES,
    })
    whatPreventsSavingDraft.push({
      message:
        'You cannot schedule a post for Facebook Pages and Facebook Groups at the same time.',
      composerId: '',
      code: VALIDATION_CODE.CHARACTER_LIMIT_REACHED,
    })

    return {
      isSavingPossible: false,
      isSavingDraftPossible: false,
      whatPreventsSaving,
      whatPreventsSavingDraft,
    }
  }

  const hasInvalidEnabledPosts = invalidEnabledPostsInfo.length > 0
  const hasInvalidEnabledDrafts = invalidEnabledDraftsInfo.length > 0

  if (hasInvalidEnabledPosts || hasInvalidEnabledDrafts) {
    return {
      isSavingPossible: false,
      isSavingDraftPossible: !hasInvalidEnabledDrafts,
      whatPreventsSaving: invalidEnabledPostsInfo,
      whatPreventsSavingDraft: invalidEnabledDraftsInfo,
    }
  }

  // Enabled drafts, if scheduled, should be scheduled for a time in the future
  const isSentPost = ComposerStore.isSentPost()
  const scheduledAt = isSentPost ? null : ComposerStore.getScheduledAt()
  const currentTimestampSeconds = Math.floor(Date.now() / 1000)
  if (scheduledAt !== null && scheduledAt <= currentTimestampSeconds) {
    const whatPreventsSaving: ValidationFeedback[] = [
      {
        code: COMPOSER_ERROR_CODE.SCHEDULE_IN_THE_PAST,
        message: preventSavingMessages.SCHEDULE_IN_THE_PAST,
        composerId: '',
      },
    ]
    return {
      ...savingNotPossible,
      whatPreventsSaving,
      whatPreventsSavingDraft: whatPreventsSaving,
    }
  }

  return { isSavingPossible: true, isSavingDraftPossible: true }
}

const updateIsSavingPossible = (): void => {
  const { isSavingPossible, whatPreventsSaving = [] } = checkIfSavingPossible()

  state.appState.isSavingPossible = isSavingPossible
  state.appState.whatPreventsSaving = whatPreventsSaving
}

const updateIsSavingDraftPossible = (): void => {
  const { isSavingDraftPossible, whatPreventsSavingDraft = [] } =
    checkIfSavingPossible()

  state.appState.isSavingDraftPossible = isSavingDraftPossible
  state.appState.whatPreventsSavingDraft = whatPreventsSavingDraft
}

const getExpandedComposerId = (): string | null =>
  state.appState.expandedComposerId

const setExpandedComposerId = (id: string | null): void => {
  if (id !== null && typeof id !== 'undefined') {
    if (ComposerStore.isDraftLocked(id)) return
  }
  state.appState.expandedComposerId = id
}

const expandProfileSubprofileDropdown = (id: string): void => {
  state.appState.expandedProfileSubprofileDropdownId = id

  const idIndex =
    state.appState.profileSubprofileDropdownsIdsToExpand.indexOf(id)
  const wasDropdownWaitingForExpansion = idIndex !== -1

  if (wasDropdownWaitingForExpansion) {
    state.appState.profileSubprofileDropdownsIdsToExpand.splice(idIndex, 1)
  }
}

const setThumbnailLoading = (isLoading: boolean): void => {
  state.appState.isThumbnailLoading = isLoading
}

const collapseProfileSubprofileDropdown = (id: string): void => {
  const isDropdownCurrentlyExpanded =
    state.appState.expandedProfileSubprofileDropdownId === id
  if (!isDropdownCurrentlyExpanded) return

  const dropdownIdsToExpand =
    state.appState.profileSubprofileDropdownsIdsToExpand
  const shouldExpandOtherDropdowns = dropdownIdsToExpand.length > 0

  if (shouldExpandOtherDropdowns) {
    const nextDropdownId = dropdownIdsToExpand[0]
    expandProfileSubprofileDropdown(nextDropdownId)
  } else {
    state.appState.expandedProfileSubprofileDropdownId = null
  }
}

const hasMultipleFacebookChannelsSelected = (): boolean => {
  const hasFacebookGroupSelected =
    state.profiles.filter(
      (profile) =>
        profile.isSelected &&
        profile.serviceType === 'group' &&
        profile.serviceName === 'facebook',
    ).length > 0
  const hasFacebookPageSelected =
    state.profiles.filter(
      (profile) =>
        profile.isSelected &&
        profile.serviceType === 'page' &&
        profile.serviceName === 'facebook',
    ).length > 0
  return hasFacebookGroupSelected && hasFacebookPageSelected
}

const queueProfileSubprofilesDropdownsIdsToExpand = (ids: string[]): void => {
  const unselectedProfileIds = ids.filter((id) => {
    const profile = AppStore.getProfile(id)
    return !profile?.isSelected && !profile?.isDisabled
  })
  if (unselectedProfileIds.length === 0) return

  state.appState.profileSubprofileDropdownsIdsToExpand.push(
    ...unselectedProfileIds,
  )

  // Automatically expand first queued profile's subprofiles dropdown
  const firstUnselectedProfileId = unselectedProfileIds[0]
  expandProfileSubprofileDropdown(firstUnselectedProfileId)
}

const emptyProfileSubprofilesDropdownsIdsToExpand = (): void => {
  state.appState.profileSubprofileDropdownsIdsToExpand = []
}

const createNewSubprofile = (
  profileId: string,
  id: string,
  avatar: string,
  name: string,
  isShared: boolean,
): void => {
  const profile = AppStore.getProfile(profileId)

  if (profile) {
    profile.subprofilesOrignatedFromAPI = false
    const subprofile = getNewSubprofile({ avatar, id, name, isShared })
    profile.subprofiles.push(subprofile)
  }
}

const refreshSubprofileData = (
  subprofiles: Subprofile[],
  profileId: string,
): void => {
  const profile = AppStore.getProfile(profileId)
  if (profile && profile.subprofiles.length < subprofiles?.length) {
    profile.subprofilesOrignatedFromAPI = true
  }
  if (profile) {
    profile.subprofiles = subprofiles.map((subprofile) =>
      getNewSubprofile(subprofile),
    )
  }
}

const setProfileSubprofileCreationState = (
  profileId: string,
  creationState: keyof typeof AsyncOperationStates,
): void => {
  const profile = AppStore.getProfile(profileId)
  if (!profile) return

  switch (creationState) {
    case AsyncOperationStates.PENDING:
      profile.appState.isSubprofileCreationPending = true
      break

    case AsyncOperationStates.DONE:
      profile.appState.isSubprofileCreationPending = false
      break

    default:
      break
  }
}

const selectGroupProfiles = (id: string): void => {
  const profileGroup = AppStore.getProfileGroup(id)
  if (!profileGroup) return

  const { profileIds: profilesIdsToSelect } = profileGroup
  // TODO: refactor the call below to an object wiht optional props rather than mult. params
  AppActionCreators.selectProfilesOnBehalfOfUser(
    profilesIdsToSelect,
    undefined,
    true,
  )
}

const unselectGroupProfiles = (
  id: string,
  selectedProfileGroupsIds: string[],
): void => {
  const group = AppStore.getProfileGroup(id)

  // Remove the unselected group's id from the list of selected groups' ids
  const unselectedGroupIndex = selectedProfileGroupsIds.indexOf(id)
  selectedProfileGroupsIds.splice(unselectedGroupIndex, 1)

  // Get all the profile ids from unique groups
  const selectedProfileIdsFromUniqueGroups = selectedProfileGroupsIds.reduce<
    string[]
  >((reducedUniqueGroupsProfileIds, selectedGroupId) => {
    const selectedGroup = AppStore.getProfileGroup(selectedGroupId)
    if (!selectedGroup) return reducedUniqueGroupsProfileIds

    const hasMatchingLength =
      selectedGroup?.profileIds.length === group?.profileIds.length

    const hasSameItems = selectedGroup?.profileIds.every((item) =>
      group?.profileIds.includes(item),
    )

    if (hasMatchingLength && hasSameItems) {
      return reducedUniqueGroupsProfileIds
    }

    return reducedUniqueGroupsProfileIds.concat(selectedGroup.profileIds)
  }, [])

  // Unselect this group's profiles that aren't selected as part of another group
  const profilesIdsToUnselect = group?.profileIds.reduce<string[]>(
    (reducedProfilesIdsToUnselect, profileIdToUnselect) =>
      selectedProfileIdsFromUniqueGroups.includes(profileIdToUnselect)
        ? reducedProfilesIdsToUnselect
        : reducedProfilesIdsToUnselect.concat(profileIdToUnselect),
    [],
  )
  AppActionCreators.unselectProfiles(profilesIdsToUnselect)
}

const toggleAllProfiles = (): void => {
  const profiles = AppStore.getProfiles()

  const [selectedProfiles, unselectedProfiles]: [
    ComposerProfile[],
    ComposerProfile[],
  ] = partition(profiles, (profile: ComposerProfile) => profile.isSelected)

  const hasSelectedProfiles = selectedProfiles.length > 0

  if (hasSelectedProfiles) {
    const selectedProfilesIds = selectedProfiles.map((profile) => profile.id)
    AppActionCreators.unselectProfiles(selectedProfilesIds)
    AppActionCreators.emptyProfileSubprofilesDropdownsIdsToExpand()
  } else {
    // Preserve last active composer's contents, if it's not empty, by making its profiles
    // the first profiles to be selected (and thus its composer the first to be enabled)
    const { lastInteractedWithComposerId } = ComposerStore.getMeta()
    const unselectedProfilesIds = unselectedProfiles
      .sort((profile) =>
        profile.service.name === lastInteractedWithComposerId ? -1 : 1,
      )
      .map((profile) => profile.id)

    profiles.forEach((profile) => {
      if (profile.subprofiles.length > 0) {
        AppActionCreators.selectSubprofile(
          profile.id,
          profile.subprofiles[0].id,
        )
        AppActionCreators.collapseProfileSubprofileDropdown(profile.id)
      }
    })
    AppActionCreators.selectProfilesOnBehalfOfUser(unselectedProfilesIds)
  }
}

const markPreviouslyExpandedComposerAsCollapsed = (): void => {
  const { expandedComposerId } = state.appState
  const expandedDraft = ComposerStore.getDraft(expandedComposerId)
  const wasOneComposerPreviouslyExpanded = expandedComposerId !== null

  if (wasOneComposerPreviouslyExpanded && !state.appState.isOmniboxEnabled) {
    state.appState.composersWhichHaveBeenCollapsed.add(expandedComposerId)
    if (expandedDraft?.hasThread()) {
      ComposerActionCreators.switchActiveThreadEditor(expandedDraft.id, 0)
    }
  }
}

const collapseComposer = (): void => {
  if (AppStore.getServicesWithSelectedProfiles().length === 1) return

  markPreviouslyExpandedComposerAsCollapsed()
  setExpandedComposerId(null)
}

const markAppAsLoaded = (): void => {
  state.appState.isLoaded = true

  // Run this on load, then profile selection / link attachment change events take over
  refreshFacebookDomainOwnershipData()
}

const resetOrganizationsData = (organizationsData: OrganizationsData): void => {
  ;(Object.keys(organizationsData) as Array<keyof OrganizationsData>).forEach(
    (key) => {
      const value = organizationsData[key]
      // @ts-expect-error TS(2322) FIXME: Type 'boolean | Organization | Organization[]' is ... Remove this comment to see the full error message
      state.organizationsData[key] = value
    },
  )
}

const updateOmniboxState = (isEnabled: boolean): void => {
  state.appState.isOmniboxEnabled = isEnabled
}

const updatePartiallySavedDraftsProfilesIds = (
  partiallySavedDrafts: Array<{ draftId: string; profilesIds: string[] }>,
): void => {
  const { partiallySavedDraftsProfilesIds } = state.appState

  // Merge each draft's previous profiles ids with new ones
  partiallySavedDrafts.forEach(({ draftId, profilesIds }) => {
    partiallySavedDraftsProfilesIds.set(draftId, [
      ...(partiallySavedDraftsProfilesIds.get(draftId) || []),
      ...profilesIds,
    ])
  })
}

const getProfileLocalTimeMoment = (
  profileId: string,
  timestamp: number,
): Dayjs => {
  const profile = AppStore.getProfile(profileId)

  let localMoment = dayjs.unix(timestamp)
  if (profile?.timezone) localMoment = localMoment.tz(profile.timezone)

  return localMoment
}

const getProfileSlotsForDay = (
  profileId: string,
  localDateMoment: Dayjs,
): Timeslot[] | undefined => {
  const { userData } = state

  if (!userData.profilesSchedulesSlots?.[profileId]) return undefined

  const profilesSchedulesSlots = userData.profilesSchedulesSlots[profileId]
  const dateString = localDateMoment.format('YYYY-MM-DD')

  return profilesSchedulesSlots[dateString]
}

/**
 * The initial data that bookmarklet.php boostraps the app with only has slot
 * data available for a limited number of days. Whenever we might need more
 * slot data (scheduledAt or profile changed), make sure we have it!
 */
const ensureProfileSchedulesSlotsDataIsAvailableForDate = (
  timestamp: number | null = null,
): void => {
  const selectedProfiles = AppStore.getSelectedProfiles()
  let isSlotPickingAvailable = selectedProfiles.length === 1
  if (!isSlotPickingAvailable) return

  let currentTimestamp = timestamp

  if (!currentTimestamp) {
    currentTimestamp = ComposerStore.getScheduledAt()
    isSlotPickingAvailable = !!timestamp

    if (!isSlotPickingAvailable || !currentTimestamp) return
  }

  const { id: profileId } = selectedProfiles[0]
  const localDateMoment = getProfileLocalTimeMoment(profileId, currentTimestamp)

  const firstDayOfMonthMoment = localDateMoment.clone().startOf('month')
  const lastDayOfMonthMoment = localDateMoment.clone().endOf('month')
  const profileSlotsForFirstDayOfMonth = getProfileSlotsForDay(
    profileId,
    firstDayOfMonthMoment,
  )
  const profileSlotsForLastDayOfMonth = getProfileSlotsForDay(
    profileId,
    lastDayOfMonthMoment,
  )

  const isSlotDataForMonthMissing =
    typeof profileSlotsForFirstDayOfMonth === 'undefined' ||
    typeof profileSlotsForLastDayOfMonth === 'undefined'

  // Fetch more data from API if we're missing data for this month
  if (isSlotDataForMonthMissing) {
    AppActionCreators.getProfileSlotDataForMonth(profileId, localDateMoment)
  }
}

const updateProfileSchedulesSlots = (
  id: string,
  slots: DayTimeslots | Record<string, never> = {},
): void => {
  const { profilesSchedulesSlots } = state.userData
  if (profilesSchedulesSlots) {
    if (!Object.prototype.hasOwnProperty.call(profilesSchedulesSlots, id))
      profilesSchedulesSlots[id] = {}
    const profileSchedulesSlots = profilesSchedulesSlots[id]

    const addDaySlotsToProfileSchedulesSlots = (dayString: string): void => {
      profileSchedulesSlots[dayString] = slots[dayString]
    }

    Object.keys(slots).forEach(addDaySlotsToProfileSchedulesSlots)
  }
}

const updateFacebookDomainOwnershipForPage = (
  id: string,
  url: string,
  isOwner: boolean,
): void => {
  let domainData = state.appState.domainsOwnedByFacebookPages.get(url)

  if (domainData === undefined) {
    domainData = new Map()
    state.appState.domainsOwnedByFacebookPages.set(url, domainData)
  }

  domainData.set(id, isOwner)
}

const resetProfilesData = (profilesData: ComposerProfile[]): void => {
  AppActionCreators.updateOmniboxState(null)
  AppActionCreators.unselectProfiles(
    AppStore.getSelectedProfiles().map(({ id }) => id),
  )
  const profilesToSelect = profilesData.filter(
    (profile) => profile.shouldBeAutoSelected,
  )
  state.appState.composersWhichHaveBeenCollapsed = new Set()

  if (profilesToSelect) {
    AppActionCreators.selectProfilesOnBehalfOfUser(
      profilesToSelect.map((profile) => profile.id),
      true,
    )
  }
}

const updateSelectedProfiles = (
  idsToSelect: string[],
  idsToUnselect: string[],
): void => {
  if (idsToSelect) {
    AppActionCreators.selectProfilesOnBehalfOfUser(idsToSelect)
  }
  if (idsToUnselect) {
    AppActionCreators.unselectProfiles(idsToUnselect)
  }
  // Reset collapsed composers set
  state.appState.composersWhichHaveBeenCollapsed = new Set()
}

const onDispatchedPayload = (payload: ComposerDispatchPayload): void => {
  const { action } = payload
  let isPayloadInteresting = true

  switch (action.actionType) {
    case ActionTypes.COMPOSER_CREATE_PROFILES:
      createProfiles(action.profilesData)
      break

    case ActionTypes.COMPOSER_SAVE_DRAFTS:
      setDraftsSavingState(
        AsyncOperationStates.PENDING,
        action.data.queueingType,
      )
      break

    case ActionTypes.APP_SELECT_SUBPROFILE:
      selectSubprofile(action.profileId, action.subprofileId)
      collapseProfileSubprofileDropdown(action.profileId)
      break

    case ActionTypes.APP_SELECT_SUBPROFILES:
      selectSubprofiles(action.idsMap, action.markAppAsLoadedWhenDone)
      action.idsMap.forEach((_: string, profileId: string) => {
        collapseProfileSubprofileDropdown(profileId)
      })
      break

    case ActionTypes.APP_UNSELECT_SUBPROFILE:
      unselectSubprofile(action.profileId)
      collapseProfileSubprofileDropdown(action.profileId)
      break

    case ActionTypes.APP_UPDATE_OMNIBOX_STATE:
      updateOmniboxState(action.isEnabled)
      break

    case ActionTypes.APP_UPDATE_PARTIALLY_SAVED_DRAFTS_PROFILES_IDS:
      updatePartiallySavedDraftsProfilesIds(action.partiallySavedDrafts)
      break

    case ActionTypes.COMPOSER_RECEIVE_SAVED_DRAFTS:
      setDraftsSavingState(AsyncOperationStates.DONE)
      break

    case ActionTypes.COMPOSER_SELECT_PROFILES_ON_BEHALF_OF_USER:
      selectProfilesOnBehalfOfUser(
        action.ids,
        action.markAppAsLoadedWhenDone,
        action.originatedFromGroupSelection,
      )
      break

    case ActionTypes.COMPOSER_SELECT_PROFILE:
      selectProfile(action.id)
      maybeAutoExpandComposer()
      ensureProfileSchedulesSlotsDataIsAvailableForDate()
      break

    case ActionTypes.COMPOSER_SELECT_PROFILES:
      action.ids.forEach((id: string, i: number) => {
        const markAppAsLoadedWhenDone =
          action.markAppAsLoadedWhenDone && i === 0
        selectProfile(
          id,
          markAppAsLoadedWhenDone,
          action.originatedFromGroupSelection,
        )
      })
      maybeAutoExpandComposer()
      ensureProfileSchedulesSlotsDataIsAvailableForDate()
      break

    case ActionTypes.UPDATE_SHORTENER_FOR_PROFILE:
      updateShortenerForProfile(action.profileId, action.domain)
      break

    case ActionTypes.COMPOSER_UNSELECT_PROFILE:
      unselectProfile(action.id)
      ensureProfileSchedulesSlotsDataIsAvailableForDate()
      break

    case ActionTypes.COMPOSER_UNSELECT_PROFILES:
      action.ids.forEach(unselectProfile)
      ensureProfileSchedulesSlotsDataIsAvailableForDate()
      break

    case ActionTypes.COMPOSER_QUEUE_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND:
      queueProfileSubprofilesDropdownsIdsToExpand(action.ids)
      break

    case ActionTypes.COMPOSER_EMPTY_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND:
      emptyProfileSubprofilesDropdownsIdsToExpand()
      break

    case ActionTypes.COMPOSER_EXPAND:
      markPreviouslyExpandedComposerAsCollapsed()
      setExpandedComposerId(action.id)
      state.appState.composersHaveBeenExpanded = true
      break

    // We consider a composer's contents saved when it's collapsed
    case ActionTypes.COMPOSER_COLLAPSE:
      collapseComposer()
      break

    case ActionTypes.COMPOSER_TRANSITION_FINISHED:
      state.appState.composerTransitionFinished = true
      isPayloadInteresting = true
      break

    // Ensure a disabled composer doesn't remain expanded
    case ActionTypes.COMPOSER_DISABLE:
      if (getExpandedComposerId() === action.id) setExpandedComposerId(null)
      break

    case ActionTypes.APP_RECEIVE_USER_DATA:
      state.userData = getNewUserData(action.userData)
      break

    case ActionTypes.APP_RECEIVE_ORGANIZATIONS_DATA:
      state.organizationsData = action.organizationsData
      break

    case ActionTypes.APP_RECEIVE_METADATA:
      state.metaData = action.metaData
      break

    case ActionTypes.APP_RECEIVE_OPTIONS:
      state.options = action.options
      break

    case ActionTypes.COMPOSER_EXPAND_PROFILE_SUBPROFILE_DROPDOWN:
      expandProfileSubprofileDropdown(action.id)
      break

    case ActionTypes.COMPOSER_COLLAPSE_PROFILE_SUBPROFILE_DROPDOWN:
      collapseProfileSubprofileDropdown(action.id)
      break

    case ActionTypes.COMPOSER_CREATE_NEW_SUBPROFILE:
      createNewSubprofile(
        action.profileId,
        action.subprofileId,
        action.avatar,
        action.name,
        action.isShared,
      )
      setProfileSubprofileCreationState(
        action.profileId,
        AsyncOperationStates.DONE,
      )
      break

    case ActionTypes.APP_REFRESH_SUBPROFILE_DATA:
      refreshSubprofileData(action.subprofileData, action.profileId)
      break

    case ActionTypes.COMPOSER_CREATE_NEW_SUBPROFILE_FAILED:
      setProfileSubprofileCreationState(
        action.profileId,
        AsyncOperationStates.DONE,
      )
      break

    case ActionTypes.COMPOSER_CREATE_NEW_SUBPROFILE_PENDING:
      setProfileSubprofileCreationState(
        action.profileId,
        AsyncOperationStates.PENDING,
      )
      break

    case ActionTypes.APP_SELECT_GROUP_PROFILES:
      selectGroupProfiles(action.id)
      break

    case ActionTypes.APP_UNSELECT_GROUP_PROFILES:
      unselectGroupProfiles(action.id, action.selectedProfileGroupsIds)
      break

    case ActionTypes.APP_TOGGLE_ALL_PROFILES:
      toggleAllProfiles()
      break

    case ActionTypes.COMPOSER_PROFILE_GROUP_CREATED:
      createProfileGroup(action.groupData)
      break

    case ActionTypes.COMPOSER_PROFILE_GROUP_UPDATED:
      updateProfileGroup(action.groupData)
      break

    case ActionTypes.APP_SET_THUMBNAIL_LOADING:
      setThumbnailLoading(action.isLoading)
      break

    case ActionTypes.COMPOSER_PROFILE_GROUP_DELETED:
      deleteProfileGroup(action.groupData)
      break

    case ActionTypes.APP_LOADED:
      markAppAsLoaded()
      break

    case ActionTypes.APP_RESET:
      state = getInitialState()
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_SCHEDULED_AT:
      ensureProfileSchedulesSlotsDataIsAvailableForDate(action.scheduledAt)
      break

    case ActionTypes.APP_REMEMBER_TWITTER_MAX_PROFILE_NOTIF_CLOSED_ONCE:
      state.appState.wasTwitterMaxOneProfileSelectedNotifClosedOnce = true
      break

    case ActionTypes.APP_RECEIVE_PROFILE_SLOT_DATA:
      updateProfileSchedulesSlots(action.id, action.slots)
      break

    case ActionTypes.APP_REFRESH_FACEBOOK_DOMAIN_OWNERSHIP_DATA:
      refreshFacebookDomainOwnershipData()
      break

    case ActionTypes.APP_RECEIVE_FACEBOOK_DOMAIN_OWNERSHIP_DATA:
      updateFacebookDomainOwnershipForPage(
        action.profileId,
        action.url,
        action.isOwner,
      )
      break

    case ActionTypes.COMPOSER_ENABLE:
      if (action.id === 'facebook') refreshFacebookDomainOwnershipData()
      break

    case ActionTypes.RESET_PROFILES_DATA:
      resetProfilesData(action.profilesData)
      break

    case ActionTypes.UPDATE_SELECTED_PROFILES:
      updateSelectedProfiles(action.idsToSelect, action.idsToUnselect)
      break

    case ActionTypes.RESET_ORGANIZATIONS_DATA:
      resetOrganizationsData(action.organizationsData)
      break

    /**
     * Changes that have an impact on state.appState.isSavingPossible and happening
     * outside of a composer's expanded state need to dispatch actions that we'll
     * explicitly listen to here in order to react to those changes. Same applies
     * to changes that'd require App to re-render, we need to listen to those too.
     */
    case ActionTypes.COMPOSER_DRAFT_ATTACHMENT_TOGGLED:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_COMMENT_CHARACTER_COUNT:
    case ActionTypes.COMPOSER_DRAFT_IMAGE_ADDED:
    case ActionTypes.COMPOSER_DRAFT_VIDEO_ADDED:
    case ActionTypes.COMPOSER_DRAFT_GIF_ADDED:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_UPDATE_TYPE:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_SELECTED_STICKERS:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_GOOGLE_BUSINESS_DATA:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_MASTODON_DATA:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_START_PAGE_DATA:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_INSTAGRAM_DATA:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_LINKEDIN_DATA:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_YOUTUBE_DATA:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_TIKTOK_DATA:
    case ActionTypes.COMPOSER_SET_LINKEDIN_CAROUSEL_TITLE:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_TITLE_CHARACTER_COUNT:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_SOURCE_LINK:
    case ActionTypes.COMPOSER_UPDATE_DRAFTS_SOURCE_LINK:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_SOURCE_LINK_DATA:
    case ActionTypes.COMPOSER_DELETE_THREADED_DRAFT:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_MEDIA_ORDER:
    case ActionTypes.COMPOSER_SWITCH_ACTIVE_THREAD_EDITOR:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_ADD_POST_TO_THREAD:
    case ActionTypes.COMPOSER_UPDATE_POST_TEXT:
    case ActionTypes.COMPOSER_DRAFT_UPDATED:
    case ActionTypes.PROFILE_DROPDOWN_HIDDEN:
    case ActionTypes.UPDATE_DRAFT_HAS_SAVING_ERROR:
    case ActionTypes.COMPOSER_UPDATE_DRAFT_IS_REMINDER:
    case ActionTypes.COMPOSER_REMOVE_DRAFT_DOCUMENT:
      isPayloadInteresting = true
      break

    case ActionTypes.COMPOSER_UPDATE_TOGGLE_SIDEBAR:
      state.appState.composerSidebarVisible = action.composerSidebarVisible
      isPayloadInteresting = true
      break

    case ActionTypes.COMPOSER_UPDATE_TOGGLE_SIDEBAR_LEFT:
      state.appState.composerLeftSidebarVisible =
        action.composerLeftSidebarVisible
      isPayloadInteresting = true
      break

    case ActionTypes.COMPOSER_UPDATE_TOGGLE_POST_PREVIEW:
      state.appState.composerPostPreviewVisible =
        action.composerPostPreviewVisible
      isPayloadInteresting = true
      break

    case ActionTypes.UPDATE_CONNECT_CHANNEL_POPOVER_VISIBLE:
      state.appState.composerSidebarVisible = false
      state.appState.connectChannelPopoverVisible =
        action.connectChannelPopoverVisible
      break

    case ActionTypes.COMPOSER_UPDATE_SIDEPANEL_ONTOP:
      state.appState.rightSidePanelVisibleOnTop = action.sidebarOnTop
      isPayloadInteresting = true
      break

    default:
      isPayloadInteresting = false
      break
  }

  if (isPayloadInteresting) {
    setTimeout(() => {
      updateIsSavingPossible()
      updateIsSavingDraftPossible()
      AppStore.emitChange()
    }, 0)
  }

  // (uncomment to enable Redux DevTools)
  // sendToMonitor('app', action, state);
}

AppDispatcher.register(onDispatchedPayload)

export default AppStore
