import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications/lib/reducer'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)

    switch (action.type) {
      case actionTypes.DRAFT_CONFIRMED_DELETE: {
        dispatch(
          dataFetchActions.fetch({
            name: 'deletePost',
            args: {
              updateId: action.updateId,
              isDraft: true,
            },
          }),
        )

        // Load more drafts if the amount of drafts drops below the batch size after deleting a draft.
        // This is to ensure that the user always has a full batch of drafts to scroll through.
        const profileState = getState().drafts.byProfileId[action.profileId]
        const moreToLoad = profileState?.moreToLoad
        const page = profileState?.page
        const draftsCount = Object.keys(profileState?.drafts).length
        // The batch size of drafts fetched on loadMore
        const draftsFetchMoreBatchSize = 20

        if (moreToLoad && draftsCount < draftsFetchMoreBatchSize) {
          dispatch(
            dataFetchActions.fetch({
              name: 'draftPosts',
              args: {
                profileId: action.profileId,
                page,
                isFetchingMore: true,
                needsApproval: false,
              },
            }),
          )
        }
        break
      }
      /*
      In Classic it's REQUESTING_DRAFT_APPROVE.
      Sends draft to queue, which means approves draft
      */
      case actionTypes.DRAFT_APPROVE:
        dispatch(
          dataFetchActions.fetch({
            name: 'approveDraft',
            args: {
              updateId: action.updateId,
              isScheduled: action.isScheduled,
            },
          }),
        )
        break
      /*
      In Classic it's REQUESTING_NEEDS_APPROVAL_UPDATE:
      Requests approval as a contributor (moves draft to awaiting approval tab if needsApproval is true,
      moves from approval tab to drafts if needsApproval false)
      */
      case actionTypes.DRAFT_NEEDS_APPROVAL:
        dispatch(
          dataFetchActions.fetch({
            name: 'changeDraftStatus',
            args: {
              updateId: action.updateId,
              needsApproval: action.needsApproval,
            },
          }),
        )
        break
      case `approveDraft_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const { isScheduled } = action.args
        const message = isScheduled
          ? 'Done! Your draft is scheduled as a post.'
          : 'Done! This draft was added to the queue.'
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message,
          }),
        )
        break
      }
      case `changeDraftStatus_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: "We've successfully moved this draft!",
          }),
        )
        break
      }
      case `approveDraft_${dataFetchActionTypes.FETCH_FAIL}`: {
        const { isScheduled } = action.args
        const message = isScheduled
          ? 'There was an error scheduling your post!'
          : 'There was an error adding this draft to the queue!'
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error || message,
          }),
        )
        break
      }
      case `changeDraftStatus_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: 'There was an error moving this draft!',
          }),
        )
        break

      // Drafts pusher events trigger a draft counter update action
      case actionTypes.DRAFT_CREATED:
      case actionTypes.DRAFT_DELETED:
      case actionTypes.DRAFT_APPROVED:
      case actionTypes.DRAFT_UPDATED:
      case actionTypes.DRAFT_MOVED: {
        const profileId = action.profileId || action.meta.arg.profileId
        if (profileId) {
          dispatch(
            dataFetchActions.fetch({
              name: 'getCounts',
              args: {
                profileId,
              },
            }),
          )
        }
        break
      }

      default:
        break
    }
  }
