import { gray, grayDarker, grayLight } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'
import CharacterCount from '../CharacterCount'

type ChannelFieldsContainerProps = {
  $isImageFirst?: boolean
  $isExpanded?: boolean
  $noBorder?: boolean
}

export const ChannelFieldsContainer = styled.div<ChannelFieldsContainerProps>`
  display: block;
  flex: 1 0 auto;
  ${(props): string | undefined =>
    props.$isImageFirst ? 'clear: both' : undefined};
  overflow: ${(props): string => (props.$isExpanded ? 'visible' : 'hidden')};
  max-height: ${(props): number => (props.$isExpanded ? 400 : 0)}px;

  --color-border-neutral: #e6ebef;

  transition: max-height 400ms ease-in-out, opacity 400ms ease-in-out,
    // Here we add differently timed transitions of border-width
    // When expanding, we want the border to be visible almost immediately
    // When collapsing, we want to wait for the collapse to almost finish, then hide the border
    ${(props): string =>
        props.$isExpanded
          ? 'border-width 100ms linear 50ms'
          : 'border-width 100ms linear 300ms'};

  ${(props): string =>
    props.$noBorder
      ? ''
      : `&:not(:empty) {
    border-top: ${
      props.$isExpanded ? `1px solid ${grayLight}` : `0px solid ${grayLight}`
    };
  }`};
`

export const Wrapper = styled.div`
  padding: 4px 16px 12px;
`

export const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;
`

export const Label = styled.label`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  color: ${grayDarker};
  min-width: 130px;
`

export const Input = styled.input`
  border-radius: 4px;
  padding: 8px;
  flex: 1 1 0;
  border: 1px solid #e6ebef;
  box-sizing: border-box;

  font-size: 12px;
  height: 32px;
  outline: none;
  color: ${grayDarker};

  &::placeholder {
    color: ${gray};
  }

  &:hover {
    border-color: ${gray};
  }

  &:focus {
    outline: 2px solid #4a67fc;
    outline-offset: 1px;
  }
`

export const ChannelFieldCharacterCount = styled(CharacterCount)`
  display: inline-block;
  background-color: #e6ebef;
  border-radius: 4px;
  padding: 3px 3px;
  line-height: 12px;
  position: absolute;
  right: 6px;
  top: 6px;
`

export const FirstCommentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
`
