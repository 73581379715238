import React, { type ChangeEvent } from 'react'

import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import { Input } from '../../channel-fields/styles'
import FooterField from '../../shared/FooterField'

export default function SourceUrl({
  draftId,
  sourceUrl,
}: {
  draftId: string
  sourceUrl: string | null
}): JSX.Element {
  const saveSourceUrl = (event: ChangeEvent<HTMLInputElement>): void => {
    const newSourceUrl = event.target.value.trim()

    ComposerActionCreators.updateDraftSourceLink(draftId, newSourceUrl)
  }

  return (
    <FooterField
      label="Destination Link"
      ariaLabel="destinationLink"
      fieldContent={
        <Input
          aria-labelledby="destinationLink"
          type="text"
          value={sourceUrl || ''}
          name="destinationLink"
          placeholder="Enter destination link..."
          onChange={saveSourceUrl}
        />
      }
    />
  )
}
