// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { reducer as formReducer } from 'redux-form'
import { asyncDataFetchReducer } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import notificationsReducer from '@bufferapp/notifications/lib/reducer'

import queueReducer from '~publish/legacy/queue/reducer'
import composerPopoverReducer from '~publish/legacy/composer-popover/reducer'
import sentReducer from '~publish/legacy/sent/reducer'
import gridReducer from '~publish/legacy/grid/reducer'
import draftsReducer from '~publish/legacy/drafts/reducer'
import generalSettingsReducer from '~publish/legacy/general-settings/reducer'
import languageSettingsReducer from '~publish/legacy/language-preferences/reducer'
import i18nReducer from '~publish/legacy/i18n/reducer'
import profileSidebarReducer from '~publish/legacy/profile-sidebar/reducer'
import clientAccessReducer from '~publish/legacy/client-access/reducer'
import modalsReducer from '~publish/legacy/modals/reducer'
import manageAppsReducer from '~publish/legacy/manage-apps-extras/reducer'
import twoFactorAuthReducer from '~publish/legacy/two-factor-auth/reducer'
import profilesDisconnectedModalReducer from '~publish/legacy/profiles-disconnected-modal/reducer'
import trialCompleteModalReducer from '~publish/legacy/trial-complete-modal/reducer'
import campaignFormReducer from '~publish/legacy/campaign-form/reducer'
import campaignReducer from '~publish/legacy/campaign/reducer'
import campaignsListReducer from '~publish/legacy/campaigns-list/reducer'
import deleteCampaignModalReducer from '~publish/legacy/delete-campaign-modal/reducer'
import hashtagGroupsReducer from '~publish/legacy/hashtag-group-manager/reducer'
import globalAccountReducer from '~publish/legacy/globalAccount/reducer'
import closeComposerModalReducer from '~publish/legacy/close-composer-confirmation-modal/reducer'
import temporaryBannerReducer from '~publish/legacy/temporary-banner/reducer'
import userReducer from '~publish/legacy/user/reducer'
import orgReducer from '~publish/legacy/organizations/reducer'
import profileNavReducer from '~publish/legacy/profile-nav/reducer'
import coachmarksReducer from '~publish/legacy/coachmarks/reducer'
import guidesReducer from '~publish/legacy/guides/reducer'
import remindersReducer from '~publish/legacy/reminders/reducer'
import { postsSlice } from '~publish/legacy/post/slice'
import { aiLimitsSlice } from '~publish/legacy/ai/state/aiLimits/slice'
import { contentIdeationSlice } from '~publish/legacy/ai/state/contentIdeation/slice'
import thirdpartyReducer from '~publish/legacy/thirdParty/reducer'
import { uploadsSlice } from '~publish/legacy/uploads/state/slice'
import { notificationsSlice } from '~publish/legacy/notifications-provider/notificationsSlice'
import { ideasSlice } from '~publish/pages/Create/state/ideasSlice'
import { integrationBarSlice } from '~publish/legacy/integrations-bar/state/integrationBarSlice'
import { contentGenerationSlice } from '~publish/legacy/ai/state/contentGeneration/slice'
import { AISlice } from '~publish/legacy/ai/state/slice'
import calendarReducer from '~publish/legacy/calendar/reducer'
import { welcomeChecklistReducer } from '../welcome-checklist/reducer'

import { environmentSlice } from './slices/environmentSlice'
import { tagsSlice } from '~publish/legacy/campaign/slice'
import { tagFormSlice } from '~publish/legacy/tag-form-modal/slice'
import { allPostsSlice } from '~publish/pages/AllChannels/slice'

export const reducers = {
  user: userReducer,
  organizations: orgReducer,
  profileNav: profileNavReducer,
  form: formReducer,
  queue: queueReducer,
  composerPopover: composerPopoverReducer,
  sent: sentReducer,
  grid: gridReducer,
  i18n: i18nReducer,
  profileSidebar: profileSidebarReducer,
  clientAccess: clientAccessReducer,
  asyncDataFetch: asyncDataFetchReducer,
  notifications: notificationsReducer,
  modals: modalsReducer,
  manageAppsExtras: manageAppsReducer,
  twoFactorAuth: twoFactorAuthReducer,
  drafts: draftsReducer,
  generalSettings: generalSettingsReducer,
  languageSettings: languageSettingsReducer,
  profilesDisconnectedModal: profilesDisconnectedModalReducer,
  thirdparty: thirdpartyReducer,
  trialCompleteModal: trialCompleteModalReducer,
  campaignForm: campaignFormReducer,
  campaign: campaignReducer,
  campaignsList: campaignsListReducer,
  deleteCampaignModal: deleteCampaignModalReducer,
  hashtagGroups: hashtagGroupsReducer,
  globalAccount: globalAccountReducer,
  closeComposerModal: closeComposerModalReducer,
  temporaryBanner: temporaryBannerReducer,
  calendar: calendarReducer,
  welcomeChecklist: welcomeChecklistReducer,
  coachmarks: coachmarksReducer,
  guides: guidesReducer,
  reminders: remindersReducer,
  // this manual wiring could be simplified with Redux Toolkit configureStore
  [notificationsSlice.name]: notificationsSlice.reducer,
  [environmentSlice.name]: environmentSlice.reducer,
  [postsSlice.name]: postsSlice.reducer,
  [uploadsSlice.name]: uploadsSlice.reducer,
  [ideasSlice.name]: ideasSlice.reducer,
  [aiLimitsSlice.name]: aiLimitsSlice.reducer,
  [contentIdeationSlice.name]: contentIdeationSlice.reducer,
  [integrationBarSlice.name]: integrationBarSlice.reducer,
  [contentGenerationSlice.name]: contentGenerationSlice.reducer,
  [tagsSlice.name]: tagsSlice.reducer,
  [AISlice.name]: AISlice.reducer,
  [tagFormSlice.name]: tagFormSlice.reducer,
  [allPostsSlice.name]: allPostsSlice.reducer,
}
