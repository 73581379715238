import { LOCATION_CHANGE } from 'redux-first-history'
import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications'
import { getMatch, preferencesAppsExtras } from '~publish/legacy/routes'

import { actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
const isAppsAndExtrasTab = (path) =>
  getMatch({
    pathname: path,
    route: preferencesAppsExtras.route,
  })?.isExact === true

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case LOCATION_CHANGE:
        if (isAppsAndExtrasTab(action.payload.location.pathname)) {
          dispatch(
            dataFetchActions.fetch({
              name: 'connectedApps',
            }),
          )
        }
        break
      case actionTypes.REQUEST_REVOKE_APP:
        dispatch(
          dataFetchActions.fetch({
            name: 'revokeConnectedApp',
            args: { appId: action.appId },
          }),
        )
        break
      case `revokeConnectedApp_${dataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: "We've revoked the access of the app",
          }),
        )
        break
      default:
        break
    }
  }
