import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications'
import { tagTab } from '~publish/legacy/routes'
import { CampaignTab } from '~publish/legacy/campaign/types'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    const state = getState()
    const currentOrganizationId = getState()?.organizations?.selected?.id
    next(action)
    switch (action.type) {
      case actionTypes.CREATE_CAMPAIGN: {
        const { name, color } = action

        dispatch(
          dataFetchActions.fetch({
            name: 'createCampaign',
            args: {
              name,
              color,
              organizationId: currentOrganizationId,
            },
          }),
        )
        break
      }
      case actionTypes.EDIT_CAMPAIGN: {
        const { id, name, color } = action

        dispatch(
          dataFetchActions.fetch({
            name: 'updateCampaign',
            args: {
              campaignId: id,
              name,
              color,
            },
          }),
        )
        break
      }
      // Complete once changes to the backend endpoint are made:
      case `createCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const { id } = action.result || {}

        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: `Great! Your new tag was created!`,
          }),
        )

        if (id) {
          dispatch(tagTab.goTo({ tagId: id, tab: CampaignTab.Scheduled }))
        }
        break
      }

      case `updateCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const { id } = action.result || {}

        if (id) {
          dispatch(tagTab.goTo({ tagId: id, tab: CampaignTab.Scheduled }))
        }
        break
      }

      case `createCampaign_${dataFetchActionTypes.FETCH_FAIL}`:
      case `updateCampaign_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        break

      default:
        break
    }
  }
