/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react'
import { isPast } from 'date-fns'
import {
  Button,
  CheckIcon,
  CloseIcon,
  CommentSquareQuoteIcon,
  CopyPlusIcon,
  DropdownMenu,
  ExternalLinkIcon,
  Flex,
  IconButton,
  LinkIcon,
  MoveFileIcon,
  PencilIcon,
  Popover,
  type PopoverProps,
  QueueBottomIcon,
  SendClockIcon,
  SendIcon,
  ShareIcon,
  Text,
  toast,
  UserCheckIcon,
} from '@buffer-mono/popcorn'

import { formatTimeZone } from '~publish/helpers/dateFormatters'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import callRpc from '~publish/legacy/utils/call-rpc'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { omitFields } from '~publish/helpers/object'
import { useTimezone } from '~publish/hooks/useTimezone'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'

import { usePostData, useTrackActionClicked } from '../PostCardContext'
import { usePublishPostNow } from '../usePublishPostNow'
import { useAddPostToQueue } from '../useAddPostToQueue'
import { useMovePostToDrafts } from '../useMovePostToDrafts'
import { useRequestPostApproval } from '../useRequestApproval'
import { useRevertPostApprovalRequest } from '../useRevertPostApprovalRequest'
import { useApprovePost } from '../useApprovePost'
import { useRejectPost } from '../useRejectPost'
import { getChannelEngageUrl } from '../helpers'

import { MoreActionsDropdown } from './MoreActionsDropdown'
import { DeletePostMenuItem } from './DeletePostMenuItem'

import styles from './PostCardActions.module.css'

const omittedFields: (keyof RpcUpdate)[] = [
  'id',
  'due_at',
  'day',
  'scheduled_at',
  'scheduledAt',
  'serviceUpdateId',
  'createdAt',
  'statistics',
  'status',
  'serviceLink',
]

type OmittedField = (typeof omittedFields)[number]

type RpcUpdateWithoutOmittedFields = Omit<RpcUpdate, OmittedField>

const omitDuplicatedFields = (
  post: RpcUpdate,
): RpcUpdateWithoutOmittedFields => {
  const newPost = omitFields(post, omittedFields)

  return {
    ...newPost,
    isSent: false,
    isPastDue: false,
    isDraft: false,
  }
}

const ScheduleConfirmationPopover = ({
  children,
  ...props
}: PopoverProps): JSX.Element | null => {
  const { dueAt, id } = usePostData()
  const [addPostToQueue, { loading }] = useAddPostToQueue()
  const trackActionClicked = useTrackActionClicked()
  const dateTimeFormatter = useDateTimeFormatter()
  const timeZone = useTimezone()
  const { triggerAttributes, editPostInComposer } = usePostComposer()

  const handleAddToQueue = useCallback(async () => {
    const { success, error } = await addPostToQueue({ variables: { id } })
    trackActionClicked('addToQueue-1')

    if (!success) {
      toast.error(`Failed to schedule post: ${error.message}`)
      return
    }

    toast.success(`Post scheduled`)
  }, [addPostToQueue, trackActionClicked, id])

  if (!dueAt) return null

  const formattedDate = dateTimeFormatter(dueAt, {
    dateFormat: "MMMM d 'at'",
    includeTimeZone: false,
  })

  return (
    <Popover {...props}>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content align="end">
        <Flex direction="column" gap="space-100">
          <Text>Your post will be published on:</Text>
          <div className={styles.schedulePreview}>
            <Flex gap="space-100" align="center">
              <SendClockIcon className={styles.icon} />
              <Text weight="medium">{formattedDate}</Text>
            </Flex>
            <Text size="xs" className={styles.timezone}>
              {formatTimeZone(timeZone)}
            </Text>
          </div>
        </Flex>
        <div className={styles.separator} />
        <Flex justify="end" gap="space-100">
          <Button
            {...triggerAttributes}
            variant="secondary"
            onClick={(): Promise<void> =>
              editPostInComposer({
                postId: id,
                cta: 'publish-allChannels-postCard-editPost-1',
              })
            }
          >
            Change
          </Button>
          <Button
            variant="primary"
            loading={loading}
            onClick={handleAddToQueue}
          >
            Schedule Post
          </Button>
        </Flex>
      </Popover.Content>
    </Popover>
  )
}

const CopyLinkMenuItem = (): JSX.Element | null => {
  const { externalLink, allowedActions } = usePostData()
  const trackActionClicked = useTrackActionClicked()

  const handleCopyLink = useCallback(async () => {
    if (!externalLink) {
      return
    }

    await navigator.clipboard.writeText(externalLink)
    trackActionClicked('copyLink-1')
    toast.success('Link copied to the clipboard')
  }, [externalLink, trackActionClicked])

  if (!externalLink || !allowedActions.includes('copyPostLink')) {
    return null
  }

  return (
    <DropdownMenu.Item onSelect={handleCopyLink}>
      <LinkIcon />
      Copy link
    </DropdownMenu.Item>
  )
}

const ShareLinkMenuItem = (): JSX.Element | null => {
  const { externalLink, allowedActions } = usePostData()
  const trackActionClicked = useTrackActionClicked()
  const { triggerAttributes, createNewPostInComposer } = usePostComposer()

  if (!externalLink || !allowedActions.includes('sharePostLink')) {
    return null
  }

  return (
    <DropdownMenu.Item
      {...triggerAttributes}
      onSelect={(): Promise<void> => {
        trackActionClicked('shareLink-1')
        return createNewPostInComposer({
          cta: 'publish-allChannels-postCard-shareLink-1',
          prefillPostData: { text: externalLink },
        })
      }}
    >
      <ShareIcon />
      Share Link in a Post
    </DropdownMenu.Item>
  )
}

const PublishNowMenuItem = (): JSX.Element | null => {
  const { id, channel, allowedActions } = usePostData()
  const trackActionClicked = useTrackActionClicked()
  const [publishPostNow, { loading }] = usePublishPostNow()

  const handlePublishNow = useCallback(
    async (e: Event) => {
      e.preventDefault()
      const { success, error } = await publishPostNow({ variables: { id } })
      trackActionClicked('publishNow-1')

      if (!success) {
        toast.error(`Post publishing failed: ${error.message}`)
        return
      }

      toast.success(`Post published to ${channel.name}`)
    },
    [publishPostNow, id, channel.name, trackActionClicked],
  )

  if (!allowedActions.includes('publishPostNow')) {
    return null
  }

  return (
    <DropdownMenu.Item loading={loading} onSelect={handlePublishNow}>
      <SendIcon />
      Publish Now
    </DropdownMenu.Item>
  )
}

const AddToQueueMenuItem = (): JSX.Element | null => {
  const { id, allowedActions, status } = usePostData()
  const trackActionClicked = useTrackActionClicked()
  const [addPostToQueue, { loading }] = useAddPostToQueue()

  const handleAddToQueue = useCallback(
    async (e: Event) => {
      e.preventDefault()
      const { success, error } = await addPostToQueue({ variables: { id } })
      trackActionClicked('addToQueue-1')

      if (!success) {
        toast.error(`Failed to add post to queue: ${error.message}`)
        return
      }

      toast.success(`Post added to queue`)
    },
    [addPostToQueue, trackActionClicked, id],
  )

  if (
    !allowedActions.includes('addPostToQueue') ||
    status === 'needs_approval'
  ) {
    return null
  }

  return (
    <DropdownMenu.Item loading={loading} onSelect={handleAddToQueue}>
      <QueueBottomIcon />
      Add to Queue
    </DropdownMenu.Item>
  )
}

const EditPostButton = (): JSX.Element | null => {
  const { allowedActions, id } = usePostData()
  const { triggerAttributes, editPostInComposer } = usePostComposer()

  if (!allowedActions.includes('updatePost')) {
    return null
  }

  return (
    <IconButton
      {...triggerAttributes}
      variant="secondary"
      label="Edit"
      tooltip="Edit"
      onClick={(): Promise<void> =>
        editPostInComposer({
          postId: id,
          cta: 'publish-allChannels-postCard-editPost-1',
        })
      }
    >
      <PencilIcon />
    </IconButton>
  )
}

const DuplicateMenuItem = (): JSX.Element | null => {
  const [loading, setLoading] = useState(false)
  const { id, channel } = usePostData()
  const { triggerAttributes, duplicatePostInComposer } = usePostComposer()
  const trackActionClicked = useTrackActionClicked()
  const cta = 'publish-allChannels-postCard-duplicatePost-1'

  const handleDuplicatePost = useCallback(async () => {
    setLoading(true)

    try {
      const post = await callRpc('getPost', { updateId: id })
      const prefillPostData = omitDuplicatedFields(post) as RpcUpdate

      duplicatePostInComposer({
        cta,
        channels: [channel.id],
        prefillPostData,
        duplicatedFrom: id,
      })
    } catch (err: unknown) {
      const error = err as Error
      toast.error(`Failed to duplicate post: ${error.message}`)
    } finally {
      setLoading(false)
      trackActionClicked('duplicate-1')
    }
  }, [id, duplicatePostInComposer, trackActionClicked, channel.id])

  return (
    <DropdownMenu.Item
      {...triggerAttributes}
      loading={loading}
      onClick={handleDuplicatePost}
    >
      <CopyPlusIcon />
      Duplicate
    </DropdownMenu.Item>
  )
}

const MoveToDraftsMenuItem = (): JSX.Element | null => {
  const { id, allowedActions } = usePostData()
  const trackActionClicked = useTrackActionClicked()
  const [movePostToDrafts, { loading }] = useMovePostToDrafts()

  const handleMoveToDrafts = useCallback(
    async (e: Event) => {
      e.preventDefault()
      const { data, error } = await movePostToDrafts({ variables: { id } })
      trackActionClicked('moveToDrafts-1')

      if (data) {
        toast.success('Post moved to drafts')
      }

      if (error) {
        toast.error(`Failed to move post to drafts: ${error.message}`)
      }
    },
    [movePostToDrafts, trackActionClicked, id],
  )

  if (!allowedActions.includes('movePostToDraft')) {
    return null
  }

  return (
    <DropdownMenu.Item loading={loading} onSelect={handleMoveToDrafts}>
      <MoveFileIcon />
      Move to Drafts
    </DropdownMenu.Item>
  )
}

const AddToQueueButton = (): JSX.Element | null => {
  const { id, allowedActions } = usePostData()
  const trackActionClicked = useTrackActionClicked()
  const [addPostToQueue, { loading }] = useAddPostToQueue()

  const handleAddToQueue = useCallback(async () => {
    const { success, error } = await addPostToQueue({ variables: { id } })
    trackActionClicked('addToQueue-1')

    if (!success) {
      toast.error(`Failed to add post to queue: ${error.message}`)
      return
    }

    toast.success(`Post added to queue`)
  }, [addPostToQueue, trackActionClicked, id])

  if (!allowedActions.includes('addPostToQueue')) {
    return null
  }

  return (
    <Button
      data-testid="add-to-queue-button"
      variant="secondary"
      loading={loading}
      onClick={handleAddToQueue}
    >
      <QueueBottomIcon /> Add to Queue
    </Button>
  )
}

const PublishNowButton = (): JSX.Element | null => {
  const { id, channel, allowedActions } = usePostData()
  const [publishPostNow, { loading }] = usePublishPostNow()
  const trackActionClicked = useTrackActionClicked()
  const handlePublishNow = useCallback(async () => {
    const { success, error } = await publishPostNow({ variables: { id } })
    trackActionClicked('publishNow-1')

    if (!success) {
      toast.error(`Post publishing failed: ${error.message}`)
      return
    }

    toast.success(`Post published to ${channel.name}`)
  }, [id, publishPostNow, trackActionClicked, channel.name])

  if (!allowedActions.includes('publishPostNow')) {
    return null
  }

  return (
    <Button variant="secondary" loading={loading} onClick={handlePublishNow}>
      <SendIcon /> Publish Now
    </Button>
  )
}

const SchedulePostButton = (): JSX.Element | null => {
  const { allowedActions } = usePostData()
  const trackActionClicked = useTrackActionClicked()

  if (!allowedActions.includes('addPostToQueue')) {
    return null
  }

  return (
    <ScheduleConfirmationPopover>
      <Button
        variant="secondary"
        onClick={(): void => trackActionClicked('schedulePost-1')}
      >
        <SendClockIcon /> Schedule Post
      </Button>
    </ScheduleConfirmationPopover>
  )
}

const ReschedulePostButton = (): JSX.Element | null => {
  const { allowedActions, id } = usePostData()
  const { triggerAttributes, editPostInComposer } = usePostComposer()
  const trackActionClicked = useTrackActionClicked()

  if (!allowedActions.includes('updatePost')) {
    return null
  }

  return (
    <Button
      {...triggerAttributes}
      variant="secondary"
      onClick={(): Promise<void> => {
        trackActionClicked('reschedule-1')
        return editPostInComposer({
          postId: id,
          cta: 'publish-allChannels-postCard-editPost-1',
        })
      }}
    >
      <SendClockIcon /> Reschedule
    </Button>
  )
}

const RequestApprovalButton = (): JSX.Element | null => {
  const { id, allowedActions } = usePostData()
  const [requestApproval, { loading }] = useRequestPostApproval()
  const trackActionClicked = useTrackActionClicked()

  const handleRequestApproval = useCallback(async () => {
    const { error, success } = await requestApproval({
      variables: { id },
    })
    trackActionClicked('requestApproval-1')

    if (!success) {
      toast.error(`Failed to request approval: ${error.message}`)
      return
    }

    toast.success('Approval requested')
  }, [requestApproval, trackActionClicked, id])

  if (!allowedActions.includes('requestPostApproval')) {
    return null
  }

  return (
    <Button variant="primary" loading={loading} onClick={handleRequestApproval}>
      <UserCheckIcon /> Request Approval
    </Button>
  )
}

const RevertApprovalRequestButton = (): JSX.Element | null => {
  const { id, allowedActions } = usePostData()
  const [revertApprovalRequest, { loading }] = useRevertPostApprovalRequest()
  const trackActionClicked = useTrackActionClicked()

  const handleRevertApprovalRequest = useCallback(async () => {
    const { error, success } = await revertApprovalRequest({
      variables: { id },
    })
    trackActionClicked('revertApprovalRequest-1')

    if (!success) {
      toast.error(`Failed to revert approval request. ${error.message}`)
      return
    }

    toast.success('Approval request reverted')
  }, [revertApprovalRequest, trackActionClicked, id])

  if (!allowedActions.includes('revertPostApprovalRequest')) {
    return null
  }

  return (
    <Button
      variant="secondary"
      loading={loading}
      onClick={handleRevertApprovalRequest}
    >
      <CloseIcon /> Revert Approval Request
    </Button>
  )
}

const ApprovePostButton = (): JSX.Element | null => {
  const { id, allowedActions, isCustomScheduled } = usePostData()
  const [approvePost, { loading }] = useApprovePost()
  const cta = isCustomScheduled ? 'Schedule' : 'AddToQueue'
  const trackActionClicked = useTrackActionClicked()

  const handleApprovePost = useCallback(async () => {
    const { error, success } = await approvePost({
      variables: { id },
    })
    trackActionClicked(`approveAnd${cta}-1`)

    if (!success) {
      toast.error(`Failed to approve post. ${error.message}`)
      return
    }

    toast.success('Post approved')
  }, [approvePost, trackActionClicked, cta, id])

  if (!allowedActions.includes('approvePost')) {
    return null
  }

  const label = isCustomScheduled
    ? 'Approve & Schedule Post'
    : 'Approve & Add to Queue'

  return (
    <Button variant="success" loading={loading} onClick={handleApprovePost}>
      <CheckIcon /> {label}
    </Button>
  )
}

const RejectPostButton = (): JSX.Element | null => {
  const { id, allowedActions } = usePostData()
  const [rejectPost, { loading }] = useRejectPost()
  const trackActionClicked = useTrackActionClicked()

  const handleRejectPost = useCallback(async () => {
    const { error, success } = await rejectPost({
      variables: { id },
    })
    trackActionClicked('reject-1')

    if (!success) {
      toast.error(`Failed to reject post. ${error.message}`)
      return
    }

    toast.success('Post rejected and moved back to drafts')
  }, [rejectPost, trackActionClicked, id])

  if (!allowedActions.includes('rejectPost')) {
    return null
  }

  return (
    <Button variant="critical" loading={loading} onClick={handleRejectPost}>
      <CloseIcon /> Reject
    </Button>
  )
}

const ViewPostButton = (): JSX.Element | null => {
  const { externalLink, allowedActions } = usePostData()
  const trackActionClicked = useTrackActionClicked()

  if (!externalLink || !allowedActions.includes('viewPost')) {
    return null
  }

  return (
    <Button
      as="a"
      href={externalLink}
      target="_blank"
      rel="noopener noreferrer"
      variant="secondary"
      onClick={(): void => trackActionClicked('viewPost-1')}
    >
      <ExternalLinkIcon /> View Post
    </Button>
  )
}

const ViewCommentsMenuItem = (): JSX.Element | null => {
  const { status, channel, metrics } = usePostData()
  const trackActionClicked = useTrackActionClicked()
  const hasComments = metrics?.find(
    ({ type, value }) => type === 'comments' && value > 0,
  )

  if (status !== 'sent' || !hasComments) {
    return null
  }

  const linkToEngage = getChannelEngageUrl(channel.service)

  if (!linkToEngage) {
    return null
  }

  const [, ...domain] = window.location.hostname.split('.')
  const url = `https://engage.${domain.join('.')}`

  return (
    <DropdownMenu.Item asChild>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(): void => trackActionClicked('viewComments-1')}
      >
        <CommentSquareQuoteIcon />
        View Comments
      </a>
    </DropdownMenu.Item>
  )
}

export const PostCardActions = (): JSX.Element | null => {
  const { status, dueAt, isCustomScheduled } = usePostData()

  if (status === 'draft') {
    const isOverDue = dueAt && isPast(dueAt)
    const shouldAddToQueue = !isOverDue && !isCustomScheduled
    return (
      <div className={styles.wrapper}>
        <RequestApprovalButton />
        {isOverDue && <ReschedulePostButton />}
        {isCustomScheduled && !isOverDue && <SchedulePostButton />}
        {shouldAddToQueue && <AddToQueueButton />}
        <EditPostButton />
        <MoreActionsDropdown>
          <MoreActionsDropdown.Group>
            <PublishNowMenuItem />
            <DuplicateMenuItem />
          </MoreActionsDropdown.Group>
          <MoreActionsDropdown.Group>
            <DeletePostMenuItem />
          </MoreActionsDropdown.Group>
        </MoreActionsDropdown>
      </div>
    )
  }

  if (status === 'needs_approval') {
    return (
      <div className={styles.wrapper}>
        <ApprovePostButton />
        <RejectPostButton />
        <RevertApprovalRequestButton />
        <EditPostButton />
        <MoreActionsDropdown>
          <MoreActionsDropdown.Group>
            <PublishNowMenuItem />
            <AddToQueueMenuItem />
            <DuplicateMenuItem />
          </MoreActionsDropdown.Group>
          <MoreActionsDropdown.Group>
            <DeletePostMenuItem />
          </MoreActionsDropdown.Group>
        </MoreActionsDropdown>
      </div>
    )
  }

  if (status === 'scheduled') {
    return (
      <div className={styles.wrapper}>
        <PublishNowButton />
        <EditPostButton />
        <MoreActionsDropdown>
          <MoreActionsDropdown.Group>
            <MoveToDraftsMenuItem />
            <DuplicateMenuItem />
          </MoreActionsDropdown.Group>
          <MoreActionsDropdown.Group>
            <DeletePostMenuItem />
          </MoreActionsDropdown.Group>
        </MoreActionsDropdown>
      </div>
    )
  }

  if (status === 'error') {
    return (
      <div className={styles.wrapper}>
        <AddToQueueButton />
        <EditPostButton />
        <MoreActionsDropdown>
          <MoreActionsDropdown.Group>
            <PublishNowMenuItem />
            <DuplicateMenuItem />
          </MoreActionsDropdown.Group>
          <MoreActionsDropdown.Group>
            <DeletePostMenuItem />
          </MoreActionsDropdown.Group>
        </MoreActionsDropdown>
      </div>
    )
  }

  if (status === 'sent') {
    return (
      <div className={styles.wrapper}>
        <ViewPostButton />

        <MoreActionsDropdown>
          <MoreActionsDropdown.Group>
            <ShareLinkMenuItem />
            <CopyLinkMenuItem />
            <DuplicateMenuItem />
          </MoreActionsDropdown.Group>
          <MoreActionsDropdown.Group>
            <ViewCommentsMenuItem />
          </MoreActionsDropdown.Group>
        </MoreActionsDropdown>
      </div>
    )
  }

  return null
}
