import React from 'react'
import PropTypes from 'prop-types'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'
import { borderRadius } from '@bufferapp/ui/style/borders'
import { grayLight } from '@bufferapp/ui/style/colors'
import { Popover, Card } from '@bufferapp/components'
import { Text, Button } from '@bufferapp/ui'
import Avatar from '@bufferapp/ui/Avatar'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useReconnectChannel } from '~publish/hooks/useReconnectChannel'

const ExtraMessageWithStyles = styled(Text)`
  margin: 0;
  font-weight: ${fontWeightBold};
`

const DisconnectedProfile = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${grayLight};
  padding: 8px;
  border-radius: ${borderRadius};
  margin: 8px 0;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`

const AvatarName = styled.div`
  margin-left: 16px;
`

const ModalContainer = styled.div`
  max-width: 470px;
  margin: 0 16px;
`

const Container = styled.div`
  position: fixed;
  z-index: 3000;
`

const PermissionText = styled(Text)`
  line-height: 32px;
  margin: 0;
`

const ProfilesDisconnectedModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'disconnectedProfiles' implicitly ... Remove this comment to see the full error message
  disconnectedProfiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'reconnectProfile' implicitly has ... Remove this comment to see the full error message
  reconnectProfile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hideModal' implicitly has an 'any... Remove this comment to see the full error message
  hideModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'displayExtraMessage' implicitly h... Remove this comment to see the full error message
  displayExtraMessage,
}) => {
  const { t } = useTranslation()
  const handleReconnectChannel = useReconnectChannel()

  const onReconnectClick = (profile: any): void => {
    handleReconnectChannel({
      id: profile.id,
      service: profile.service,
      cta: `publish-modal-channelRefresh-${profile.service.toLowerCase()}-1`,
    })
  }
  return (
    <Container>
      <Popover onOverlayClick={hideModal}>
        <Card reducedPadding>
          <ModalContainer>
            <Text type="h3">{t('profiles-disconnected-modal.headline')}</Text>
            {displayExtraMessage && (
              <ExtraMessageWithStyles type="p">
                {t('profiles-disconnected-modal.extraMessage.instagram')}
              </ExtraMessageWithStyles>
            )}
            <Text type="p">{t('profiles-disconnected-modal.body1')}</Text>
            <Text type="p">{t('profiles-disconnected-modal.body2')}</Text>
            {/* @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type. */}
            {disconnectedProfiles.map((p) => (
              <DisconnectedProfile key={p.id}>
                <AvatarContainer>
                  <Avatar
                    src={p.avatar_https}
                    fallbackUrl="https://s3.amazonaws.com/buffer-ui/Default+Avatar.png"
                    alt={p.handle}
                    size="small"
                    type="social"
                    network={p.service}
                  />
                  <AvatarName>
                    {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
                    <Text>{p.formatted_username}</Text>
                  </AvatarName>
                </AvatarContainer>
                {p.isAdmin && p.isAdmin === 'false' ? (
                  <PermissionText type="p">Contact an org admin</PermissionText>
                ) : (
                  // @ts-expect-error TS(2740) FIXME: Type '{ disabled: any; onClick: () => void; size: ... Remove this comment to see the full error message
                  <Button
                    disabled={p.reconnecting}
                    onClick={(): void => onReconnectClick(p)}
                    size="small"
                    type="primary"
                    label={
                      p.reconnecting
                        ? t('profiles-disconnected-modal.reconnecting')
                        : t('profiles-disconnected-modal.cta')
                    }
                  />
                )}
              </DisconnectedProfile>
            ))}
          </ModalContainer>
        </Card>
      </Popover>
    </Container>
  )
}

ProfilesDisconnectedModal.propTypes = {
  disconnectedProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  reconnectProfile: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  displayExtraMessage: PropTypes.bool,
}

ProfilesDisconnectedModal.defaultProps = {
  displayExtraMessage: false,
}

export default ProfilesDisconnectedModal
