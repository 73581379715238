import { useQuery } from '@apollo/client'
import React, { useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  BetaBadge,
  Button,
  ChannelAvatar,
  CoachMark,
  CriticalIcon,
  EmptyState,
  Flex,
  IconButton,
  MessageCircleHeartIcon,
  NewBadge,
  PlusIcon,
  SettingsIcon,
  ShopgridIcon,
  Skeleton,
  SkeletonText,
  Text,
} from '@buffer-mono/popcorn'

import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useSplitEnabled } from '@buffer-mono/features'

import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { FilterByTag } from '~publish/components/FilterByTag'
import { NewPostComposerTrigger } from '~publish/components/NewPostComposerTrigger'
import { PostViewToggle } from '~publish/components/PostViewToggle/PostViewToggle'
import { graphql } from '~publish/gql'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { TimezoneProvider } from '~publish/hooks/useTimezone'
import {
  useAccountId,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'
import { useFullStory } from '~publish/legacy/thirdParty/hooks/useFullStory'
import type { GetChannelInfoQuery, PostStatus } from '~publish/gql/graphql'
import { PageLayout } from '~publish/components/PageLayout'
import {
  PostEmptyStateByTab,
  type PostTab,
  PostTabs,
} from '~publish/components/PostTabs'
import { RegisteredBannersProvider } from '~publish/components/RegisteredBanner'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { useLocalStorageState } from '~publish/hooks/useLocalStorageState'

// TODO: fetching post counts and list should be extracted to common folders
import { PostList, PostListSkeleton } from '../AllChannels/PostList'
import { usePostCounts } from '../AllChannels/PostList/usePostCounts'
import { QueueLimitNotice } from './QueueLimitNotice'
import { QueueList } from './QueueList'
import { QueuePausedNotice } from './QueuePausedNotice'
import { ChannelProvider, useSelectedChannel } from './ChannelContext'
import { BlueskyPromotionalBanner } from './PromotionalBanners/BlueskyPromotionalBanner'
import { MetaThreadsPromotionalBanner } from './PromotionalBanners/MetaThreadsPromotionalBanner'
import { CHANNEL_TOUR_STORAGE_KEY, TourStorageStep } from './ChannelTour'
import { DisconnectedChannelNotice } from './DisconnectedChannelNotice'
import { TimezoneDisplay } from '~publish/components/TimezoneDisplay'
import { useAppDispatch } from '~publish/legacy/store'
import { RefreshLinkedInForAnalyticsPromotionalBanner } from './PromotionalBanners/RefreshLinkedInForAnalyticsPromotionalBanner'
import { mapPostStatusToTab } from '~publish/helpers/post'
import { SetupInstagramRemindersPromotionalBanner } from './PromotionalBanners/SetupInstagramRemindersPromotionalBanner'
import { TiktokImagesPromotionalBanner } from '~publish/pages/Channel/PromotionalBanners/TiktokImagesPromotionalBanner'

// TODO: replace serverUrl with metadata.serverUrl, at the moment doesn't seem to work
/*
metadata {
    __typename
    ... on MastodonMetadata {
      serverUrl
    }
  }
*/
export const ChannelPage_Channel = graphql(/* GraphQL */ `
  fragment ChannelPage_Channel on Channel {
    ...QueueList_Channel
    accessLevel
    id
    avatar
    isQueuePaused
    isDisconnected
    name
    metadata {
      ... on LinkedInMetadata {
        __typename
        shouldShowLinkedinAnalyticsRefreshBanner
      }
    }
    locationData {
      location
    }
    hasActiveMemberDevice
    serverUrl
    service
    timezone
    type
  }
`)
export const GetChannelInfo = graphql(/* GraphQL */ `
  query GetChannelInfo(
    $channelId: ChannelId!
    $organizationId: OrganizationId!
  ) {
    channel(input: { id: $channelId }) {
      ...QueueList_Channel
      ...ChannelPage_Channel
      id
      avatar
      isQueuePaused
      name
      locationData {
        location
      }
      serverUrl
      service
      timezone
    }

    tags(input: { organizationId: $organizationId }) {
      id
      ...FilterByTag_Tag
    }
  }
`)

const SettingsCoachMark = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const banner = useDismissableBanner('settings-button-coachmark')
  const [showCoachMark, setShowCoachMark] = React.useState(false)
  const [isCoachMarkOpen, setIsCoachMarkOpen] = React.useState(true)
  const [channelTourCurrentStep] = useLocalStorageState(
    CHANNEL_TOUR_STORAGE_KEY,
    TourStorageStep.INTRO,
  )

  React.useEffect(() => {
    if (channelTourCurrentStep === TourStorageStep.CLOSED) {
      setShowCoachMark(true)
    }
  }, [channelTourCurrentStep])

  if (!banner.isActive || !showCoachMark) {
    return <>{children}</>
  }

  return (
    <CoachMark
      open={isCoachMarkOpen}
      onDismiss={banner.dismiss}
      onOpenChange={(open): void => {
        setIsCoachMarkOpen(open)
      }}
    >
      <CoachMark.Overlay>
        {children}
        <CoachMark.Spotlight />
      </CoachMark.Overlay>
      <CoachMark.Content showCloseIcon={false}>
        <CoachMark.Title>Channel Settings have a new home</CoachMark.Title>
        <CoachMark.Description>
          Same channel settings, easier to access.
        </CoachMark.Description>
        <CoachMark.Footer>
          <CoachMark.Dismiss>
            <Button>Got It</Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}

const availableTabs: PostTab[] = ['queue', 'drafts', 'approvals', 'sent']

export const ChannelPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const id = useParams<{ id: string }>().id
  const [tab = 'queue', setTab] = useQueryParam<PostTab>('tab')
  const [tagsQueryParam = [], setTagFilter] = useQueryParam<string[]>('tags')

  const { initFullStory } = useFullStory()

  const { isEnabled: isSecondaryButtonTreatmentEnabled } = useSplitEnabled(
    'geid-secondary-button-treatment-with-global-action',
  )

  const organizationId = useCurrentOrganization()?.id

  const { data, error } = useQuery(GetChannelInfo, {
    variables: { organizationId, channelId: id },
    fetchPolicy: 'cache-and-network',
  })
  const { counts, loading: countsLoading } = usePostCounts({
    organizationId,
    channelIds: [id],
    tagIds: tagsQueryParam,
  })

  const tags = sanitizeNullableArray(data?.tags)
  const channel = data?.channel

  useEffect(
    function checkTabQueryParam() {
      if (!availableTabs.includes(tab)) {
        setTab(mapPostStatusToTab(tab as PostStatus))
      }
    },
    [setTab, tab],
  )

  // tracking
  useEffect(() => {
    initFullStory()
  }, [initFullStory])

  const accountId = useAccountId()

  useEffect(() => {
    BufferTracker.singleChannelOpened({
      tab,
      organizationId,
      accountId: accountId || '',
      channelSelected: id,
      tagsSelected: tagsQueryParam.length === 0 ? undefined : tagsQueryParam,
    })
  }, [organizationId, id, tagsQueryParam, tab, accountId])

  // FIXME: this is a workaround for missing images in the sent posts tab for
  // posts that we fetched from instagram and have expiring image URLs
  // Triggering gridPosts will execute fetchRecentMedia in buffer-web
  // @see: https://buffer.atlassian.net/browse/CT-993
  const fetchedGridPosts = useRef<Set<string>>(new Set())
  useEffect(() => {
    if (channel?.service === 'instagram' && !fetchedGridPosts.current.has(id)) {
      dispatch(
        dataFetchActions.fetch({
          name: 'gridPosts',
          args: {
            profileId: id,
          },
        }),
      )
      fetchedGridPosts.current.add(id)
    }
  }, [dispatch, id, fetchedGridPosts, channel?.service])

  if (error) {
    return (
      <EmptyState size="xlarge" variant="critical">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Failed to load</EmptyState.Heading>
        <EmptyState.Description>
          Error happened, please let our team know about it.{' '}
          <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  if (!channel) {
    return (
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="space-200">
              <Skeleton rounded width={48} height={48} />
              <Skeleton rounded width={100} height={20} />
            </Flex>
            <PageLayout.Actions>
              <Skeleton width={90} height={40} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="space-200">
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </Flex>
            <PageLayout.Actions>
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
        </PageLayout.Header>
        <PageLayout.Container size="narrow">
          <PostListSkeleton />
        </PageLayout.Container>
      </PageLayout>
    )
  }

  const tabs: { id: PostTab; count: number | null | undefined }[] = [
    { id: 'queue', count: counts?.queue },
    { id: 'drafts', count: counts?.drafts },
    { id: 'approvals', count: counts?.approvals },
    { id: 'sent', count: counts?.sent },
  ]

  const tabCountLimit = counts?.limit

  const filterApplied = tagsQueryParam.length > 0

  return (
    <ChannelProvider channel={channel}>
      <TimezoneProvider timeZone={channel.timezone}>
        <RegisteredBannersProvider
          key={`registered-banner-provider-${channel.name}`}
        >
          <PostTabs
            asChild
            value={tab}
            // TODO: this should not be necessary once we remnove shop grid from tabs
            onChange={(selectedTab): void => setTab(selectedTab as PostTab)}
          >
            <PageLayout>
              <PageLayout.NotificationsContainer>
                <ChannelPageNotifications />
              </PageLayout.NotificationsContainer>
              <PageLayout.Header>
                <PageLayout.HeaderRow>
                  <ChannelPageTitle channel={channel} />
                  <PageLayout.Actions>
                    <FeedbackWidget id="single-channel-1" source="publish">
                      <Button variant="tertiary" size="large">
                        <MessageCircleHeartIcon />
                        Share Feedback
                      </Button>
                    </FeedbackWidget>
                    <PostViewToggle />
                    <NewPostComposerTrigger
                      cta="publish-singleChannel-header-newPost-1"
                      channels={[id]}
                    >
                      <Button
                        data-testid="queue-header-create-post"
                        size="large"
                        variant={
                          isSecondaryButtonTreatmentEnabled
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        <PlusIcon /> New Post
                      </Button>
                    </NewPostComposerTrigger>
                  </PageLayout.Actions>
                </PageLayout.HeaderRow>
                <PageLayout.HeaderRow>
                  <PostTabs.TabList>
                    {tabs.map((tab) => (
                      <PostTabs.Tab
                        key={tab.id}
                        value={tab.id}
                        count={tab.count}
                        countLimit={tabCountLimit}
                        countLoading={countsLoading}
                      />
                    ))}
                  </PostTabs.TabList>
                  <PageLayout.Actions>
                    <FilterByTag
                      data-tour-id="tags-filter"
                      tags={tags}
                      value={tagsQueryParam}
                      onSelect={setTagFilter}
                    />

                    <TimezoneDisplay
                      channelId={id}
                      timezone={channel.timezone}
                    />
                  </PageLayout.Actions>
                </PageLayout.HeaderRow>
              </PageLayout.Header>

              <PageLayout.Container size="narrow" as="main">
                <PostTabs.Panel value="queue" asChild>
                  <QueueList
                    key={`${id}-queue`}
                    channel={channel}
                    tagIds={tagsQueryParam.length ? tagsQueryParam : undefined}
                  />
                </PostTabs.Panel>

                {(['drafts', 'approvals', 'sent'] as const).map((tab) => (
                  <PostTabs.Panel key={`${id}-${tab}`} value={tab} asChild>
                    <PostList
                      key={`${id}-${tab}`}
                      status={tab}
                      channelIds={[id]}
                      tagIds={
                        tagsQueryParam.length ? tagsQueryParam : undefined
                      }
                      emptyState={
                        !filterApplied && (
                          <PostEmptyStateByTab tab={tab}>
                            <NewPostComposerTrigger
                              cta="publish-singleChannel-emptyState-newPost-1"
                              channels={[id]}
                            >
                              <Button size="large">
                                <PlusIcon /> New Post
                              </Button>
                            </NewPostComposerTrigger>
                          </PostEmptyStateByTab>
                        )
                      }
                    />
                  </PostTabs.Panel>
                ))}
              </PageLayout.Container>
            </PageLayout>
          </PostTabs>
        </RegisteredBannersProvider>
      </TimezoneProvider>
    </ChannelProvider>
  )
}

export function ChannelPageNotifications(): JSX.Element {
  const selectedChannel = useSelectedChannel()

  return (
    <>
      {/* Notices */}
      <QueueLimitNotice />
      {selectedChannel?.isQueuePaused && <QueuePausedNotice />}
      <DisconnectedChannelNotice />

      {/* Banners - First rendered has highest priority */}
      <TiktokImagesPromotionalBanner />
      <RefreshLinkedInForAnalyticsPromotionalBanner />
      <BlueskyPromotionalBanner />
      <MetaThreadsPromotionalBanner />
      <SetupInstagramRemindersPromotionalBanner />
    </>
  )
}

export function ChannelPageTitle({
  channel,
}: {
  channel?: GetChannelInfoQuery['channel'] | null
}): JSX.Element {
  const { isBetaEnabled } = useCurrentOrganization()

  if (!channel) return <></>

  const secondaryChannelText =
    channel.serverUrl || channel.locationData?.location

  return (
    <Flex direction="row" align="center" gap="space-200">
      <ChannelAvatar
        src={channel.avatar}
        alt={channel.service}
        channel={channel.service}
        size="large"
      />
      <div>
        <Flex direction="row" align="center" gap="space-100">
          <PageLayout.Title>{channel.name}</PageLayout.Title>
          {channel.service === 'instagram' && (
            <IconButton
              id="shop-grid"
              variant="tertiary"
              label="Shop Grid"
              tooltip="Open Shop Grid"
              size="small"
              as={Link}
              to={`/channels/${channel.id}/shop-grid`}
            >
              <ShopgridIcon />
            </IconButton>
          )}
          <SettingsCoachMark>
            <IconButton
              id="settings-menu"
              variant="tertiary"
              label="Channel settings"
              tooltip="Channel Settings"
              size="small"
              as={Link}
              to={`/channels/${channel.id}/settings`}
            >
              <SettingsIcon />
            </IconButton>
          </SettingsCoachMark>

          {isBetaEnabled ? (
            <BetaBadge size="small" />
          ) : (
            <NewBadge size="small" />
          )}
        </Flex>
        {secondaryChannelText && (
          <Text color="subtle">{secondaryChannelText}</Text>
        )}
      </div>
    </Flex>
  )
}
