import styled from 'styled-components'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import TextareaAutosize from 'react-textarea-autosize'
import { grayDarker, gray } from '@bufferapp/ui/style/colors'

export const PostTypeWrapper = styled.div`
  position: relative;
  clear: left;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ced7df;
  justify-content: space-between;
`

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;
  flex: 1;
`

export const FieldWrapper = styled.div`
  display: flex;
  gap: 24px;
`

export const RightFloatWrapper = styled.div<{
  removeTopMargin?: boolean
  withBottomMargin?: boolean
}>`
  position: relative;
  clear: left;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin: ${(props): string => (props.removeTopMargin ? '0' : '12px')} 0
    ${(props): string => (props.withBottomMargin ? '4px' : '0')} 0;
`

export const RadioLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  margin-right: 16px;
  color: ${grayDarker};
`

export const RadioInput = styled.input`
  margin-top: 0px;
  margin-right: 6px;
  cursor: pointer;
`

export const Label = styled.label`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: #3d3d3d;
  width: 130px;
`

export const TimeLabel = styled(Label)`
  width: 120px;
`

export const TextWrapLabel = styled.label`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: #3d3d3d;
  width: 160px;
  line-height: 15px;
`

export const SelectLabel = styled.label`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: #3d3d3d;
  width: 130px;
`

export const Textarea = styled(TextareaAutosize)`
  resize: none;
  min-height: 64px;
  max-height: 120px;
  overflow-y: scroll;
`

export const DateTimeInput = styled.input`
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #e6ebef;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  height: 32px;
  outline: none;
  color: ${grayDarker};
  text-align: left;
  background: none;
  line-height: 12px;
  flex: 1 1 0;

  &:hover {
    border-color: ${gray};
  }

  &:focus,
  &[aria-expanded='true'] {
    outline: 2px solid #4a67fc;
    outline-offset: 1px;
  }
`
