import {
  type Line,
  makeLine,
  makeLineFollowingNewline,
  makeWord,
} from './lines'

/**
 * Calculate lines from a body of text and width/lines constraints
 * Lines contain content and metadata about the line's measurements
 * @param text Raw input text
 * @param lineWidth width of the line in pixels
 * @param lineClamp number of lines to show (similar to line-clamp)
 * @param measureText function to measure text
 * @returns Line[] and boolean indicating if the text was truncated
 */
export const calculateLines = (
  text: string,
  lineWidth: number,
  lineClamp: number,
  measureText: (text: string) => number,
): [Line[], boolean] => {
  /*
   * +------------------------------------------------------------------------------+
   * | Core calculateion logic:                                                     |
   * | iterate through each line and word and determine if it fits within the       |
   * | remaining width in the current line.                                         |
   * | We iterate by word to emulate natural line breaks.                           |
   * +------------------------------------------------------------------------------+
   */

  // Split by newlines, then by words to find natural line breaks + spaces
  const textSplit = text
    .trim()
    .split('\n')
    .map((line) => (line.trim() === '' ? [] : line.trim().split(' ')))

  // Create output datastructure, seeded with a single blank line
  const lines: Line[] = [makeLine()]

  if (text.trim() === '') return [lines, false]

  // Pre-compute the size of a space character - will be used a lot!
  const sizeOfSpace = measureText(' ')

  // iterate through each line and word
  for (let lineIdx = 0; lineIdx < textSplit.length; lineIdx++) {
    const textLine = textSplit[lineIdx]
    const isLastInputLine = lineIdx === textSplit.length - 1
    for (let wordIdx = 0; wordIdx < textLine.length; wordIdx++) {
      const currentLine = lines[lines.length - 1]
      const word = textLine[wordIdx]
      const wordLength = measureText(word)

      // If we're continuining on the same line, we need to account for the leading space.
      // If on a new line, the space is hidden (visually accounted for by newline)
      // If it's the first word in the line, we don't need to add space
      const wordWithSpace =
        wordIdx === 0 ? wordLength : wordLength + sizeOfSpace
      const currentLineExtended = currentLine.width + wordWithSpace

      // [[ Switch on if/how the word fits into the line/body ]]

      // CASE: word fits!
      // We're still under the max length of the line, add it to the current line and index
      // Note - special case for the first word in the line, where we don't want to break
      // onto the next line so we always add the word to the current line
      // TODO - would be great to implement word breaks here
      if (currentLineExtended <= lineWidth || currentLine.width === 0) {
        currentLine.width += wordWithSpace
        currentLine.words.push(makeWord(word, wordLength, wordWithSpace))
        continue
      }

      // CASE: word doesn't fit!
      // We're over the end of the limit, so we need to "create" a new line
      // We need to check first to make sure we're not out of lines
      if (lines.length < lineClamp) {
        lines.push(makeLine([makeWord(word, wordLength, wordLength)]))
        continue
      }

      // CASE: No fit, out of lines!
      // This means we've reached the end of the text, return what we have
      return [lines, true]
    }

    // Below we handle newlines - we know we need a new
    // line since the string is split by \n

    // CASE: New line, but not lines left!
    // Return what we have
    if (lines.length === lineClamp) {
      // we truncated if there're more lines!
      return [lines, !isLastInputLine]
    }

    // Regular newline, only add if there're more lines to show
    if (!isLastInputLine) {
      lines.push(makeLineFollowingNewline())
    }
  }

  return [lines, false]
}
