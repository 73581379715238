import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount } from '~publish/legacy/accountContext'
import { CLIENT_NAMES, SEGMENT_NAMES } from '~publish/legacy/constants'
import { toggleReminders } from '~publish/legacy/general-settings/thunks/toggleReminders'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { selectModalPlacement } from '~publish/legacy/modals/selectors'
import {
  selectCurrentProfileId,
  selectDefaultToReminders,
  selectedProfileServiceName,
  selectIsManagerInChannel,
  selectNotificationsEnabled,
  selectProfileServiceType,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'

import {
  Button,
  Flex,
  Heading,
  Label,
  Link,
  Paragraph,
  Separator,
  Switch,
} from '@buffer-mono/popcorn'
import commonStyles from '../../GeneralSettings.module.css'

export function RemindersSettings({
  isRemindersOnly = false,
}: {
  isRemindersOnly?: boolean
}): JSX.Element {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization

  const profileId = useAppSelector(selectCurrentProfileId)
  const notificationsEnabled = useAppSelector((state) =>
    selectNotificationsEnabled(state, [profileId]),
  )
  const isManager = useAppSelector(selectIsManagerInChannel(profileId))
  const defaultToReminders = useAppSelector((state) =>
    selectDefaultToReminders(state, profileId),
  )
  const openWizardButtonCopy = notificationsEnabled
    ? t('remindersSetupWizard.testNotificationsButton')
    : t('remindersSetupWizard.setUpNotificationsButton')

  const remindersSetUpWizardPlacement: string = useAppSelector((state) =>
    selectModalPlacement(state),
  )

  const onRemindersSetupButtonClick = (): void => {
    BufferTracker.remindersSetupCtaClicked({
      organizationId: currentOrganization?.id || null,
      clientName: CLIENT_NAMES.web,
      cta: SEGMENT_NAMES.REMINDERS_SETTINGS,
      placement: remindersSetUpWizardPlacement,
    })
    dispatch(
      modalActions.showRemindersWizardModal({ placement: 'generalSettings' }),
    )
  }

  useEffect(() => {
    if (currentOrganization?.id) {
      BufferTracker.remindersSetupCtaViewed({
        organizationId: currentOrganization?.id || null,
        clientName: CLIENT_NAMES.web,
        cta: SEGMENT_NAMES.REMINDERS_SETTINGS,
        placement: remindersSetUpWizardPlacement,
      })
    }
  }, [currentOrganization])

  const onToggle = (): void => {
    dispatch(
      toggleReminders({
        profileId,
        allowReminders: !defaultToReminders,
      }),
    )
  }

  const profileName = useAppSelector((state) =>
    selectedProfileServiceName(state),
  )
  const profileType = useAppSelector(selectProfileServiceType(profileId))

  const profileNameMap = {
    facebook: 'Meta',
    instagram: 'Instagram',
  }

  return (
    <>
      <Flex
        style={{ width: '100%' }}
        justify="between"
        align="center"
        data-testid="reminders-settings-wrapper"
      >
        <Flex direction="column" gap="space-50">
          <Heading size="small" as="h3">
            {isRemindersOnly
              ? t('remindersSettings.notificationsOnlyChannels.title')
              : t('remindersSettings.title')}
          </Heading>
          <Paragraph className={commonStyles.settingsParagraph}>
            {isRemindersOnly
              ? t('remindersSettings.notificationsOnlyChannels.description', {
                  profileName:
                    profileNameMap[profileName as keyof typeof profileNameMap],
                  profileType,
                })
              : t('remindersSettings.description')}{' '}
            {!isRemindersOnly ? (
              <Link
                href="https://support.buffer.com/article/658-using-notification-publishing?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
                external={true}
              >
                {t('remindersSettings.learnMore')}
              </Link>
            ) : null}
          </Paragraph>
        </Flex>
        <Flex
          direction="column"
          align="end"
          gap="space-150"
          className={commonStyles.actionsWrapper}
        >
          {!isRemindersOnly && (
            <Flex gap="space-100" align="center">
              <Label>
                {defaultToReminders
                  ? t('remindersSettings.toggleLabel.on')
                  : t('remindersSettings.toggleLabel.off')}
              </Label>
              <Switch
                id="instagram-reminders-switch"
                disabled={!isManager || !notificationsEnabled}
                checked={defaultToReminders}
                onCheckedChange={onToggle}
              />
            </Flex>
          )}
          <Button
            size="large"
            onClick={onRemindersSetupButtonClick}
            variant={notificationsEnabled ? 'secondary' : 'primary'}
            className={commonStyles.actionButton}
            role="button"
          >
            {openWizardButtonCopy}
          </Button>
        </Flex>
      </Flex>
      <Separator />
    </>
  )
}
