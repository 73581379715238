import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { getMatch, tagsPage } from '~publish/legacy/routes'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions as notificationActions } from '@bufferapp/notifications'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    const state = getState()
    switch (action.type) {
      case actionTypes.DELETE_CAMPAIGN: {
        const { campaign } = state.deleteCampaignModal
        dispatch(
          dataFetchActions.fetch({
            name: 'deleteCampaign',
            args: {
              campaignId: campaign.id,
            },
          }),
        )
        break
      }
      case `deleteCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const label = 'tag'

        dispatch(modalActions.hideDeleteCampaignModal())
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: `Your ${label} has been deleted!`,
          }),
        )
        const route = tagsPage.route
        const inTagsOrCampaignsPage =
          getMatch({
            pathname: state.router?.location?.pathname,
            route,
          })?.isExact === true
        if (!inTagsOrCampaignsPage) {
          dispatch(tagsPage.goTo())
        }
        break
      }
      case `deleteCampaign_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(modalActions.hideDeleteCampaignModal())
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        break
      default:
        break
    }
  }
