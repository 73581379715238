import { Button, Link, PromotionalBanner } from '@buffer-mono/popcorn'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCommonTrackingProperties } from '~publish/hooks/useCommonTrackingProperties'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { CLIENT_NAMES, SEGMENT_NAMES } from '~publish/legacy/constants'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { selectModalPlacement } from '~publish/legacy/modals/selectors'
import { useAppSelector } from '~publish/legacy/store'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { RegisteredBanner } from '../../../components/RegisteredBanner'

export const SetupInstagramRemindersPromotionalBanner =
  (): JSX.Element | null => {
    const bannerId = 'remindersSetup'
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const organizationId = useOrganizationId()
    const banner = useDismissableBanner(bannerId)
    const selectedChannel = useSelectedChannel()

    const commonTrackingProperties = useCommonTrackingProperties()
    const remindersSetUpWizardPlacement = useAppSelector(selectModalPlacement)
    const trackingData = React.useMemo(
      () => ({
        organizationId,
        clientName: CLIENT_NAMES.web,
        cta: SEGMENT_NAMES.REMINDERS_BANNER,
        placement: remindersSetUpWizardPlacement,
        ...commonTrackingProperties,
      }),
      [organizationId, commonTrackingProperties, remindersSetUpWizardPlacement],
    )

    const canRenderBanner =
      !!selectedChannel &&
      !selectedChannel.hasActiveMemberDevice &&
      selectedChannel.service === 'instagram' &&
      banner.isActive

    useEffect(() => {
      if (canRenderBanner) {
        BufferTracker.remindersSetupCtaViewed(trackingData)
      }
    }, [canRenderBanner, trackingData])

    const onRemindersSetupButtonClick = (): void => {
      BufferTracker.remindersSetupCtaClicked(trackingData)
      dispatch(
        modalActions.showRemindersWizardModal({
          placement: 'setupRemindersBanner',
        }),
      )
    }

    return (
      <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
        <PromotionalBanner onDismiss={banner.dismiss}>
          <PromotionalBanner.Content>
            <PromotionalBanner.Heading>
              Use native Instagram features in your posts
            </PromotionalBanner.Heading>
            <PromotionalBanner.Description>
              Share your post as a Reminder, and we&apos;ll send you a mobile
              notification when it&apos;s time to publish. You can then finish
              up your post in Instagram.&nbsp;
              <Link
                href={`https://support.buffer.com/article/658-using-notification-publishing?${HC_UTM_PARAMS}`}
                external
              >
                Learn more here.
              </Link>
            </PromotionalBanner.Description>
            <PromotionalBanner.Actions>
              <Button variant="primary" onClick={onRemindersSetupButtonClick}>
                {t('remindersSetupWizard.setUpNotificationsButton')}
              </Button>
            </PromotionalBanner.Actions>
          </PromotionalBanner.Content>
          <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/reminders-banner.png" />
        </PromotionalBanner>
      </RegisteredBanner>
    )
  }
