// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import reduxDynamicMiddlewares from 'redux-dynamic-middlewares'

import { asyncDataFetchMiddleware } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import notificationsMiddleware from '@bufferapp/notifications/lib/middleware'

import queueMiddleware from '~publish/legacy/queue/middleware'
import sentMiddleware from '~publish/legacy/sent/middleware'
import gridMiddleware from '~publish/legacy/grid/middleware'
import draftsMiddleware from '~publish/legacy/drafts/middleware'
import generalSettingsMiddleware from '~publish/legacy/general-settings/middleware'
import languageSettingsMiddleware from '~publish/legacy/language-preferences/middleware'
import profileSidebarMiddleware from '~publish/legacy/profile-sidebar/middleware'
import clientAccessMiddleware from '~publish/legacy/client-access/middleware'
import bootstrapMiddleware from '~publish/legacy/bootstrap/middleware'
import i18nMiddleware from '~publish/legacy/i18n/middleware'
import pusherSyncMiddleware from '~publish/legacy/pusher-sync/middleware'
import modalsMiddleware from '~publish/legacy/modals/middleware'
import manageAppsMiddleware from '~publish/legacy/manage-apps-extras/middleware'
import twoFactorAuthMiddleware from '~publish/legacy/two-factor-auth/middleware'
import dateTimePreferencesMiddleware from '~publish/legacy/date-time-preferences/middleware'
import maintenanceRedirectMiddleware from '~publish/legacy/maintenance-redirect/middleware'
import notificationsProviderMiddleware from '~publish/legacy/notifications-provider/middleware'
import profilesDisconnectedModalMiddleware from '~publish/legacy/profiles-disconnected-modal/middleware'
import campaignFormMiddleware from '~publish/legacy/campaign-form/middleware'
import campaignMiddleware from '~publish/legacy/campaign/middleware'
import campaignsListMiddleware from '~publish/legacy/campaigns-list/middleware'
import deleteCampaignModalMiddleware from '~publish/legacy/delete-campaign-modal/middleware'
import trialCompleteModalMiddleware from '~publish/legacy/trial-complete-modal/middleware'
import hashtagGroupsMiddleware from '~publish/legacy/hashtag-group-manager/middleware'
import segmentTrackingMiddleware from '~publish/legacy/analytics-middleware/middleware'
import globalAccountMiddleware from '~publish/legacy/globalAccount/middleware'
import closeComposerModalMiddleware from '~publish/legacy/close-composer-confirmation-modal/middleware'
import temporaryBannerMiddleware from '~publish/legacy/temporary-banner/middleware'
import profileNavMiddleware from '~publish/legacy/profile-nav/middleware'
import thirdpartyMiddleware from '~publish/legacy/thirdParty/middleware'
import profilesMiddleware from '~publish/legacy/profiles/middleware'
import orgMiddleware from '~publish/legacy/organizations/middleware'
import userMiddleware from '~publish/legacy/user/middleware'

// @ts-expect-error TS(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
export const getMiddleware = (routerMiddleware) => [
  routerMiddleware,
  asyncDataFetchMiddleware,
  i18nMiddleware,
  profileSidebarMiddleware,
  clientAccessMiddleware,
  bootstrapMiddleware,
  userMiddleware,
  orgMiddleware,
  profilesMiddleware,
  queueMiddleware,
  sentMiddleware,
  gridMiddleware,
  generalSettingsMiddleware,
  languageSettingsMiddleware,
  pusherSyncMiddleware,
  notificationsMiddleware,
  manageAppsMiddleware,
  modalsMiddleware,
  twoFactorAuthMiddleware,
  dateTimePreferencesMiddleware,
  maintenanceRedirectMiddleware,
  draftsMiddleware,
  notificationsProviderMiddleware,
  profilesDisconnectedModalMiddleware,
  thirdpartyMiddleware,
  campaignFormMiddleware,
  campaignMiddleware,
  campaignsListMiddleware,
  deleteCampaignModalMiddleware,
  trialCompleteModalMiddleware,
  hashtagGroupsMiddleware,
  segmentTrackingMiddleware,
  globalAccountMiddleware,
  closeComposerModalMiddleware,
  temporaryBannerMiddleware,
  profileNavMiddleware,
  // Dynamic middlware insertion point (for lazy-loaded components)
  reduxDynamicMiddlewares,
]
